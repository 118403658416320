import { Draggable } from "react-beautiful-dnd";
import React from "react";


const ListItem = ({ item, index }) => {

  return (

    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        return (
          <div className="drag-item"
            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <img className="image-card" src={item.content} alt='test' />
          </div>
        );
      }}
    </Draggable>

  );
};

export default ListItem;
