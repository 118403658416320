import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './MemorySubGame.css';
import '../../../utils/ActionsButton/ActionsButton.css';
import Header from '../../../utils/Header/Header';
import Footer from '../../../utils/Footer/Footer';
import 'react-jigsaw-puzzle/lib/jigsaw-puzzle.css'
import PageEsp from '../../../content/page-esp.json';
import PageEng from '../../../content/page-eng.json';
import { fireConfetti } from '../../../utils/CanvasConfetti/CanvasConfetti'
import MemorySubGameFeedback from './MemorySubGameFeedback/MemorySubGameFeedback'
import AudioPlayer from 'react-h5-audio-player';
import MemorySubGameBadge from './MemorySubGameBadge/MemorySubGameBadge';

class MemorySubGame extends React.Component {

  constructor(props) {
    super();
    this.state = {
      props: props,
      feedback: false,
      badge: false,
      cardIdFeedback: 0,
      matches: 0,
      userCanClick: true,
      firstPositionClicked: 0,
      SecondPositionClicked: 0,
      cardSelectedWithinTurn: 1,
      currentSubModule: 1,
      currentGame: {
        name: "",
        type: "",
        cards: []
      },
      cardDistribution: [
        {
          "status": "hide",
          "cardId": 1,
        },
        {
          "status": "hide",
          "cardId": 2
        },
        {
          "status": "hide",
          "cardId": 3
        },
        {
          "status": "hide",
          "cardId": 4
        },
        {
          "status": "hide",
          "cardId": 1
        },
        {
          "status": "hide",
          "cardId": 3
        },
        {
          "status": "hide",
          "cardId": 4
        },
        {
          "status": "hide",
          "cardId": 2
        }
      ]
      };
    this.changeItem = this.changeItem.bind(this);
    this.getCardInformation = this.getCardInformation.bind(this);
    this.hideFeedback = this.hideFeedback.bind(this);
    this.hideBadge = this.hideBadge.bind(this);
    this.displayBadge = this.displayBadge.bind(this);
  };

  componentDidMount() {
    this.setState({ currentSubModule: parseInt(this.props.match.params.submodule) });
    this.prepareGame(this.props.match.params.submodule);
  }

  prepareGame(item) {

    let language = this.state.props.state.language;
    let PageContent;
    if (language === 1) {
      PageContent = PageEsp;
    } else {
      PageContent = PageEng;
    }

    let filteredItem = PageContent.filter(submodule => submodule.submodule === parseInt(item));
    if (filteredItem[0].games.length > 0) {
      this.setState({ currentGame: filteredItem[0].games[0] });
    }
    if(this.state.props.state.badges[1].obtained === true){
      this.state.cardDistribution.forEach(card => card.status = 'match');
    }
  }

  playSuccessAudio() {
    let successAudio = new Audio('/audios/background/Extras/Success.mp3');
    successAudio.play();
  }

  playErrorAudio() {
    let errorAudio = new Audio('/audios/background/Extras/Error.mp3');
    errorAudio.play();
  }

  goSubModule() {
    this.props.history.push('/submodule/' + this.state.currentSubModule);
  }

  changeItem(item) {
    this.props.history.push('/submodule/' + item);
  }

  getCardInformation(cardId) {
    let card = this.state.currentGame.cards.filter(card => card.id === cardId);
    return card;
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  hideFeedback() {
    this.setState({ feedback: false });
  }

  hideBadge() {
    this.setState({ badge: false });
  }

  displayBadge(event) {
    event.preventDefault();
    this.setState({ badge: true });
  }

  cardClicked(cardPosition) {

    let cardState = this.state.cardDistribution[cardPosition].status;

    if (cardState === 'match' && this.state.cardSelectedWithinTurn === 1) {
      this.setState({ feedback: true });
      this.setState({ cardIdFeedback: this.state.cardDistribution[cardPosition].cardId });
    }
    else
      if (this.state.userCanClick === true && cardState === 'hide') {

        let newCardDistribution = this.state.cardDistribution;
        newCardDistribution[cardPosition].status = 'display';
        this.setState({ cardDistribution: newCardDistribution });

        if (this.state.cardSelectedWithinTurn === 1) {
          //first card;
          this.setState({ firstPositionClicked: cardPosition })
          this.setState({ cardSelectedWithinTurn: 2 });

        } else if (this.state.cardSelectedWithinTurn === 2) {

          if (this.state.firstPositionClicked !== cardPosition) {
            //Second card;
            if (this.state.cardDistribution[this.state.firstPositionClicked].cardId === this.state.cardDistribution[cardPosition].cardId) {

              //Congratulate user
              this.playSuccessAudio();
              fireConfetti();

              //Displaying feedback
              this.setState({ feedback: true });
              this.setState({ cardIdFeedback: this.state.cardDistribution[cardPosition].cardId });

              //Defining matches
              let matches = this.state.matches + 1;
              this.setState({ matches: matches });
              newCardDistribution = this.state.cardDistribution;
              newCardDistribution[this.state.firstPositionClicked].status = 'match';
              newCardDistribution[cardPosition].status = 'match';
              this.setState({ cardDistribution: newCardDistribution });

              //Displaying batch
              if (matches === 4) {
                this.setState({ badge: true });

                //Save badge on general state
                let badges = this.state.props.state.badges;
                //1 is the position for Origin badge.
                badges[1].obtained = true;
                //Calling dispatch function.
                this.props.badgeObtained(badges);

                // to delete just to debug.
                badges = this.state.props.state.badges;
              }


            } else {
              this.playErrorAudio();
              this.setState({ userCanClick: false });
              this.sleep(1500).then(() => {
                newCardDistribution = this.state.cardDistribution;
                newCardDistribution[this.state.firstPositionClicked].status = 'hide';
                newCardDistribution[cardPosition].status = 'hide';
                this.setState({ cardDistribution: newCardDistribution });
                this.setState({ userCanClick: true });
              });
            }

            //Restart turn card selection
            this.setState({ cardSelectedWithinTurn: 1 });
          }
        }
      }


  }

  render() {

    let feedback = '';
    if (this.state.feedback === true) {
      feedback = <MemorySubGameFeedback language={this.props.state.language} hideFeedback={this.hideFeedback} feedback={this.getCardInformation(this.state.cardIdFeedback)[0].feedback} />
    }

    let badge = '';
    if ( ( (this.state.matches === 4 && this.state.feedback === false ) || this.state.props.state.badges[1].obtained === true) && this.state.badge === true ) {
      badge = <MemorySubGameBadge language={this.props.state.language} hideBadge={this.hideBadge} />
    }

    let results = '';
    if ((this.state.matches === 4 && this.state.feedback === false ) || this.state.props.state.badges[1].obtained === true) {
      results = <button className="results" onClick={(event) => this.displayBadge(event)}>{this.props.state.language === 1 ? 'Insignia obtenida' : 'Obtained badge'}</button>
    }

    return (

      <div className="page__container">

        <Header submodule="true" changeItem={this.changeItem} />

        <div className="question-content" style={{ backgroundImage: 'url(/images/backgrounds/origen.webp)' }}>

          <div className="game-audio-container">
            <div className="background-audio">
              <div className="audio-text__container">
                <p>{this.props.state.language === 1 ? 'Audio de fondo' : 'Background audio'}</p>
              </div>
              <AudioPlayer
                autoPlay
                loop
                showControls={false}
                showDownloadProgress={false}
                showJumpControls={false}
                customAdditionalControls={[]}
                src='/audios/background/module1/2.mp3'
              />
            </div>
          </div>

          {badge}
          {feedback}

          <div className="title">
            <h1>{this.props.state.language === 1 ? 'Juego de Memoria' : 'Memory Game'}</h1>
          </div>

          <div className="memory">

            {
              this.state.cardDistribution.map((card, indexCard) => {
                let currentCard = this.getCardInformation(card.cardId);
                let image = '';

                if (currentCard.length === 0) {
                  currentCard = [{ id: 0, source: "", feedback: "" }];
                } else {

                  image =
                    <img src={"/images/logo/logo" + (this.props.state.language === 1 ? '-esp' : '-eng') + '.png' } alt="Insignia obtenida" />;

                  if (card.status === 'display' || card.status === 'match') {
                    image = <img src={currentCard[0].source} alt="Insignia obtenida" />;
                  }
                }

                return (
                  <div key={'card-' + indexCard} className="memory-card" onClick={() => this.cardClicked(indexCard)}>
                    {image}
                  </div>
                )
              })
            }

          </div>

          <div className="actions">
            <button className="goSubModule" onClick={() => this.goSubModule()}>{this.props.state.language === 1 ? 'Volver al submódulo' : 'Back to Submódule'}</button>
            {results}
          </div>

        </div>

        <Footer />

      </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    state: state
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    badgeObtained: (badges) => {
      dispatch({ type: 'BADGE_OBTAINED', badges });
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemorySubGame));
