import React, { Component } from 'react';
import './Menu.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ModuleEsp from '../../content/Home_Menu-modules-esp.json';
import ModuleEng from '../../content/Home_Menu-modules-eng.json';
import UtilEsp from '../../content/util-esp.json';
import UtilEng from '../../content/util-eng.json';
import { parse } from 'dom-parser-react'
import Game from '../../utils/GeneralGameInstructions/GeneralGameInstructions';

class Menu extends Component {

  constructor(props) {
    super();
    this.state = {
      game: false,
      props
    };
    this.redirectSubModules = this.redirectSubModules.bind(this);
    this.openGame = this.openGame.bind(this);
    this.closeGame = this.closeGame.bind(this);
    this.startGame = this.startGame.bind(this);
  }

  redirectSubModules(subModule) {
    this.state.props.history.push('/submodule/' + subModule);
  }

  redirectHome() {
    this.state.props.history.push('/');
  }

  openGame() {
    this.setState({ game: true });
  }

  closeGame() {
    this.setState({ game: false });
  }

  startGame() {
    this.props.history.push('/generalGame');
  }


  render() {

    let language = this.state.props.state.language;
    let util;
    let module;
    if (language === 1) {
      util = UtilEsp;
      module = ModuleEsp;
    } else {
      util = UtilEng;
      module = ModuleEng;
    }

    let game = '';
    if (this.state.game) {
      game = <Game language={this.props.state.language} closeAction={this.closeGame} startGame={this.startGame} />;
    }

    return (
      <div className="menu-container">
        <div className="menu">
          <div className="menu__header">
            <img
              className="menu__close"
              src="/images/icon/close.png"
              onClick={() => this.state.props.closeAction()}
              alt="Cerrar menú."
            />
          </div>

          {game}

          <div className="menu__info-block">
            <p href="/" onClick={() => { this.redirectHome(); this.state.props.closeAction() }}>{util[0].home}</p>
            { (this.props.location.pathname !== '/generalGame') ? <p className="submenu" onClick={() => { this.openGame();}}>{util[0].generalGame}</p> : ''}
            <p href="/badgeCenter" onClick={() => { this.props.history.push('/badgeCenter'); this.state.props.closeAction() }} >{util[0].badges}</p>
            <p href="/visits" onClick={() => { this.props.history.push('/visits'); this.state.props.closeAction() }}>{util[0].visits}</p>
            <p href="/credits" onClick={() => { this.props.history.push('/credits'); this.state.props.closeAction() }} >{util[0].credits}</p>
            <p href="/" >{util[0].language}</p>
            <div className="menu__info__block--lang-div">
              <div href="/" className={language === 0 ? 'lan__selected' : 'none'} onClick={() => { this.redirectHome(); this.props.changeLanguage(0); this.state.props.closeAction() }}>
                <p>{util[0].english}</p>
              </div>
              <div href="/" className={language === 1 ? 'lan__selected' : 'none'} onClick={() => { this.redirectHome(); this.props.changeLanguage(1); this.state.props.closeAction() }}>
                <p>{util[0].spanish}</p>
              </div>
            </div>
            {
              module.map((module, index) => {
                return (
                  <React.Fragment key={index}>
                    <p> {parse(module.name, {
                      createElement: React.createElement,
                      Fragment: React.Fragment,
                    })} </p>
                    {
                      module.submodules.map((submodule, indexSubModule) => {
                        return (
                          <p key={index + indexSubModule} className="submenu" onClick={() => { ((this.props.isSubmodule && this.props.location.pathname !== '/generalGame' && this.props.location.pathname !== '/game' && this.props.location.pathname !== '/badgeCenter' && this.props.location.pathname !== '/credits' && this.props.location.pathname !== '/references' && this.props.location.pathname !== '/visits') ? this.props.changeItem(submodule.id) : this.redirectSubModules(submodule.id)); this.state.props.closeAction() }} >{parse(submodule.name, {
                            createElement: React.createElement,
                            Fragment: React.Fragment,
                          })}</p>
                        )
                      })
                    }
                  </React.Fragment>
                )
              })
            }
            <p href="/references" onClick={() => { this.props.history.push('/references'); this.state.props.closeAction() }} >{util[0].references}</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) => {
      dispatch({ type: 'CHANGE_LANGUAGE', language });
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
