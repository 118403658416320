import React, { Component } from 'react';
import './QuestionsSubGame.css';
import '../../../utils/ActionsButton/ActionsButton.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../../../utils/Header/Header';
import Footer from '../../../utils/Footer/Footer';
import subGameQuestionEsp from '../../../content/test-game-content-esp.json';
import subGameQuestionEng from '../../../content/test-game-content-eng.json';
import { fireConfetti } from '../../../utils/CanvasConfetti/CanvasConfetti'
import AudioPlayer from 'react-h5-audio-player';

class QuestionsSubGame extends Component {

  constructor(props) {
    super();
    this.state = {
      currentSubModule: 0,
      currentQuestionDetailes: {
        question: "",
        options: [],
        userAnswered: false,
        userAnswer: 0,
        correctOption: 0,
        feedback: '',
        image: '',
        background: "",
      },
      userAnswered: false,
      userAnsweredCorrect: false,
      userFinished: false,
      props: props
    };
    this.changeItem = this.changeItem.bind(this);
  };

  componentDidMount() {
    this.setState({ currentSubModule: parseInt(this.props.match.params.submodule) });
    this.changeDetailes(this.props.state.badges[3].currentQuestion);
  }

  playSuccessAudio() {
    let successAudio = new Audio('/audios/background/Extras/Success.mp3');
    successAudio.play();
  }

  playErrorAudio() {
    let errorAudio = new Audio('/audios/background/Extras/Error.mp3');
    errorAudio.play();
  }

  changeDetailes(currentQuestion) {

    let language = this.state.props.state.language;
    let PageContent;
    if (language === 1) {
      PageContent = subGameQuestionEsp;
    } else {
      PageContent = subGameQuestionEng;
    }

    if (currentQuestion !== -1) {
      let filteredQuestion = PageContent.filter(question => question.id === currentQuestion);
      let userAnswered = false;
      let userAnsweredCorrect = false;
      let userAnswer = this.props.state.badges[3].answers.filter(answer => answer.question === currentQuestion);


      if (userAnswer.length >= 1) {
        filteredQuestion[0].userAnswered = true;
        userAnswered = true;
        filteredQuestion[0].userAnswer = userAnswer[0].answer;
        userAnsweredCorrect = userAnswer[0].answer === filteredQuestion[0].correctOption;
      } else {
        filteredQuestion[0].userAnswered = false;
        filteredQuestion[0].userAnswered = -1;
      }

      if (userAnsweredCorrect === true) {
        this.playSuccessAudio();
        fireConfetti();
      } else {
        if (userAnswered) {
          this.playErrorAudio();
        }
      }

      this.setState({ currentQuestionDetailes: filteredQuestion[0], userAnsweredCorrect: userAnsweredCorrect, userAnswered: userAnswered });
    } else {

      let textEsp = "¡Felicidades! Usted ha respondido correctamente el total de " + this.props.state.badges[3].correctAnswers + (this.props.state.badges[3].correctAnswers === 1 ? " pregunta " : " preguntas ") + "de un máximo posible de 10.";
      let textEng = "Congratulations! You answered correctly " + this.props.state.badges[3].correctAnswers + (this.props.state.badges[3].correctAnswers === 1 ? " question " : " questions ") + "of a total of 10.";

      let text;
      if(this.state.props.state.language === 1){
        text = textEsp;
      }else{
        text = textEng;
      }

      let emptyQuestion = {
        question: text,
        options: [],
        userAnswered: false,
        userAnswer: 0,
        correctOption: 0,
        feedback: '',
        image: '',
        background: "url(/images/backgrounds/Agua.webp)",
      }

      this.playSuccessAudio();
      fireConfetti();

      //Assigning Badge;
      let badges = this.state.props.state.badges;
      badges[3].obtained = true;
      this.props.badgeObtained(badges);

      this.setState({ currentQuestionDetailes: emptyQuestion, userAnsweredCorrect: false, userAnswered: false });
    }
  }

  previous() {
    if (this.props.state.badges[3].currentQuestion > 1 || this.props.state.badges[3].currentQuestion === -1) {
      let previous = this.props.state.badges[3].currentQuestion === -1 ? subGameQuestionEsp.length : this.props.state.badges[3].currentQuestion - 1;
      this.props.changeSeaCurrentQuestion(previous);
      this.changeDetailes(previous);
    }
  }

  next() {
    if (this.props.state.badges[3].currentQuestion < subGameQuestionEsp.length && this.props.state.badges[3].currentQuestion !== -1) {
      let next = this.props.state.badges[3].currentQuestion + 1;
      this.props.changeSeaCurrentQuestion(next);
      this.changeDetailes(next);
    }
  }

  results() {
    this.setState({ userFinished: true });
    this.props.changeSeaCurrentQuestion(-1);
    this.changeDetailes(-1);
  }

  goSubModule() {
    this.props.history.push('/submodule/' + this.state.currentSubModule);
  }

  changeItem(item) {
    this.props.history.push('/submodule/' + item);
  }

  goBadgeCenter(){
    this.state.props.history.push('/badgeCenter');
  }

  userAnswered(optionSelected) {
    if ((this.state.userAnswered !== true && this.state.currentQuestionDetailes.userAnswered !== true)) {
      let answers = this.props.state.badges[3].answers;
      answers.push({ 'question': this.props.state.badges[3].currentQuestion, 'answer': optionSelected });
      let userAnsweredCorrect = optionSelected === this.state.currentQuestionDetailes.correctOption;

      let correctAnswers = this.props.state.badges[3].correctAnswers;

      if (userAnsweredCorrect === true) {
        this.playSuccessAudio();
        fireConfetti();
        correctAnswers = correctAnswers + 1;
      } else {
        this.playErrorAudio();
      }
      this.setState({ userAnswered: true, userAnsweredCorrect: userAnsweredCorrect});
      this.props.addSeaGameAnswer(answers);
      this.props.changeTotalSeaCorrectAnswers(correctAnswers);
    }
  }

  render() {

    let title = this.state.props.state.language ? 'Prueba': 'Quiz';
    if (this.state.userFinished === true && this.props.state.badges[3].currentQuestion === -1) {
      title = this.state.props.state.language ? 'Resultados de la prueba': 'Quiz results';
    }

    let feedback = '';
    if (this.state.userAnswered === true || this.state.currentQuestionDetailes.userAnswered === true) {
      document.getElementById('Footer')?.scrollIntoView({ behavior: 'smooth' });
      feedback =
        <div className="feedback">
          <h1>{this.state.userAnsweredCorrect === true ? (this.state.props.state.language ? 'Correcto': 'Correct') : (this.state.props.state.language ? 'Incorrecto': 'Incorrect')}</h1>
          <p>{this.state.currentQuestionDetailes.feedback}</p>
        </div>;
    } else {
      document.getElementById('Header')?.scrollIntoView({ behavior: 'smooth' });
    }

    let previousButton = '';
    if (this.props.state.badges[3].currentQuestion > 1 || this.props.state.badges[3].currentQuestion === -1) {
      previousButton = <button onClick={() => this.previous()}>{this.state.props.state.language ? 'Anterior': 'Previous'}</button>
    }

    let nextButton = '';
    if (this.state.userAnswered === true && this.props.state.badges[3].currentQuestion < subGameQuestionEsp.length) {
      nextButton = <button onClick={() => this.next()}>{this.state.props.state.language ? 'Siguiente': 'Next'}</button>
    }

    let results = '';
    if (this.state.userAnswered === true && this.props.state.badges[3].currentQuestion === subGameQuestionEsp.length && this.props.state.badges[3].currentQuestion !== -1) {
      results = <button className="results" onClick={() => this.results()}>{this.state.props.state.language ? 'Ver resultados': 'See results'}</button>
    }

    let badgeCenter = '';
    if (this.props.state.badges[0].currentQuestion === -1) {
      badgeCenter = <button onClick={() => this.goBadgeCenter()}>{this.state.props.state.language === 1 ? 'Insignias obtenidas' : 'Badge center'}</button>
    }

    let result = '';
    if ((this.state.userFinished === true && this.props.state.badges[3].currentQuestion === -1)  || (this.props.state.badges[3].obtained === true && this.props.state.badges[3].currentQuestion === -1) ){

      let badgeTextEsp = 'Ha completado el juego.';
      let badgeTextEng = 'You have completed the game.';

      let textToDisplay;
      if(this.state.props.state.language === 1){
        textToDisplay = badgeTextEsp;
      }else{
        textToDisplay = badgeTextEng;
      }

      result =
        <div className="results">
          <div className="results_badge">
            <img src={"/images/subGame/" + (this.props.state.language === 1 ? 'mar.png' : 'mar-eng.png')} alt="badge obtained" />
          </div>
          <div className="results_description">
            <p>{textToDisplay}</p>
          </div>
        </div>;
    }

    let image = <div>
      <img className="feedback-image" src={ '/images/content/' + this.state.currentQuestionDetailes.image} alt="imagen alusiva" />
      <p className="image-credits">{this.state.currentQuestionDetailes.imageCredits}</p>
    </div>;
    if ((this.state.userFinished === true && this.props.state.badges[3].currentQuestion === -1) || (this.props.state.badges[3].obtained === true && this.props.state.badges[3].currentQuestion === -1)) {
      image = '';
    }

    let goSubModule = '';
    if (this.props.state.badges[3].currentQuestion === -1) {
      goSubModule = <button className="goSubModule" onClick={() => this.goSubModule()}>{this.state.props.state.language ? 'Volver al submódulo': 'Back to submodule'}</button>
    }


    return (

      <div className="page__container">

        <Header submodule="true" changeItem={this.changeItem}/>

        <div className="question-content" style={{ backgroundImage: this.state.currentQuestionDetailes.background }}>

          <div className="game-audio-container">
            <div className="background-audio">
              <div className="audio-text__container">
                <p>{this.state.props.state.language ? 'Audio de fondo': 'Background audio'}</p>
              </div>
              <AudioPlayer
                autoPlay
                loop
                showControls={false}
                showDownloadProgress={false}
                showJumpControls={false}
                customAdditionalControls={[]}
                src='/audios/background/module3/1.mp3'
              />
            </div>
          </div>

          <div className="title">
            <h1>{title}</h1>
          </div>

          <div className="question">
            <p>{this.state.currentQuestionDetailes.question}</p>
            {image}
          </div>

          <div className="options">

            {
              this.state.currentQuestionDetailes.options.map((option, index) => {
                return (
                  <button key={option + '-' + index} className={(this.state.userAnswered === true && this.state.currentQuestionDetailes.userAnswer === option) ? 'selected' : 'no-selected'} onClick={() => this.userAnswered(option.id)}>
                    <span className="flexible" >{option.description}</span>
                    <span></span>
                  </button>
                )
              })
            }
          </div>

          {feedback}

          {result}

          <div className="actions">
            {previousButton}
            {goSubModule}
            {results}
            {badgeCenter}
            {nextButton}
          </div>

        </div>

        <Footer />

      </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    badgeObtained: (badges) => {
      dispatch({ type: 'BADGE_OBTAINED', badges });
    },
    addSeaGameAnswer: (answers) => {
      dispatch({ type: 'ADD_SEA_GAME_ANSWER', answers });
    },
    changeTotalSeaCorrectAnswers: (correctAnswers) => {
      dispatch({ type: 'CHANGE_SEA_GAME_CORRECT_ANSWERS', correctAnswers });
    },
    changeSeaCurrentQuestion: (currentQuestion) => {
      dispatch({ type: 'CHANGE_SEA_GAME_CURRENT_QUESTION', currentQuestion });
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionsSubGame));
