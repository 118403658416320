import React, { Component } from 'react';
import './PuzzleSubGame.css';
import '../../../utils/ActionsButton/ActionsButton.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../../../utils/Header/Header';
import Footer from '../../../utils/Footer/Footer';
import { JigsawPuzzle } from 'react-jigsaw-puzzle/lib'
import 'react-jigsaw-puzzle/lib/jigsaw-puzzle.css'
import PageEsp from '../../../content/page-esp.json';
import PageEng from '../../../content/page-eng.json';
import { fireConfetti } from '../../../utils/CanvasConfetti/CanvasConfetti'
import AudioPlayer from 'react-h5-audio-player';
import PuzzleSubGameBadge from './PuzzleSubGameBadge/PuzzleSubGameBadge';

class PuzzleSubGame extends Component {

  constructor(props) {
    super();
    this.state = {
      showFeedback: false,
      currentGame: {
        name: "",
        type: "",
        imageSrc: "",
        title: "",
        feedback: ""
      },
      badge: false,
      props: props
    };
    this.changeItem = this.changeItem.bind(this);
    this.displayBadge = this.displayBadge.bind(this);
    this.hideBadge = this.hideBadge.bind(this);
    this.goSubModule = this.goSubModule.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
  };

  componentDidMount() {
    this.prepareGame(this.props.match.params.submodule, false);
  }

  prepareGame(item, userChange) {

    let language = this.state.props.state.language;
    let PageContent;
    if (language === 1) {
      PageContent = PageEsp;
    } else {
      PageContent = PageEng;
    }

    let filteredItem = PageContent.filter(submodule => submodule.submodule === parseInt(item));

    if (filteredItem[0].games.length > 0) {
      this.setState({ currentGame: filteredItem[0].games[this.props.state.badges[2].currentQuestion] });
    }

    if (this.props.state.badges[2].correctAnswers > this.props.state.badges[2].currentQuestion) {
      this.setState({ showFeedback: true });
      fireConfetti();
      this.playSuccessAudio();
    } else {
      this.setState({ showFeedback: false });
    }

  }

  playSuccessAudio() {
    let successAudio = new Audio('/audios/background/Extras/Success.mp3');
    successAudio.play();
  }

  userFinished() {

    this.setState({ showFeedback: true });
    fireConfetti();
    this.playSuccessAudio();
    document.getElementById('subgame-feedback')?.scrollIntoView({ behavior: 'smooth' });

    let answers = this.props.state.badges[2].answers;
    answers.push({ 'question': this.props.state.badges[2].currentQuestion, 'answer': true });

    let correctAnswers = this.props.state.badges[2].correctAnswers;
    correctAnswers = correctAnswers + 1;

    let badges = this.state.props.state.badges;
    if (correctAnswers === 4) {
      badges[2].obtained = true;
      this.props.badgeObtained(badges);
      this.setState({ badge: true });
    }

    this.props.addPuzzleGameAnswer(answers);
    this.props.changeTotalPuzzleCorrectAnswers(correctAnswers);
  }

  goSubModule() {
    this.props.history.push('/submodule/' + this.props.match.params.submodule);
  }

  changeItem(item) {
    this.props.history.push('/submodule/' + item);
  }

  displayBadge(event) {
    event.preventDefault();
    this.setState({ badge: true });
  }

  hideBadge() {
    this.setState({ badge: false });
  }

  previous() {
    if (this.props.state.badges[2].currentQuestion >= 1) {
      let previous = this.props.state.badges[2].currentQuestion - 1;
      this.props.changePuzzleCurrentQuestion(previous);
      this.prepareGame(this.props.match.params.submodule, true);
    }
  }

  next() {
    if (this.state.showFeedback === true && this.props.state.badges[2].currentQuestion < 4) {
      let next = this.props.state.badges[2].currentQuestion + 1;
      this.props.changePuzzleCurrentQuestion(next);
      this.prepareGame(this.props.match.params.submodule, true);
    }
  }

  render() {

    let feedback = '';
    if (this.state.showFeedback === true) {
      feedback = <p>{this.state.currentGame.feedback}</p>
    }

    let puzzle = '';
    if (this.state.showFeedback === false) {
      puzzle = <div className="puzzle">
        <JigsawPuzzle
          imageSrc={this.state.currentGame.imageSrc}
          rows={2}
          columns={2}
          onSolved={() => this.userFinished()}
        />
      </div>
    }

    let image = '';
    if (this.state.showFeedback === true) {
      image = <img className="puzzle" src={this.state.currentGame.imageSrc} alt={this.state.props.state.language ? 'alusiva al módule' : 'related to module'} />
    }

    let badge = '';
    if (this.state.badge === true) {
      badge = <PuzzleSubGameBadge language={this.state.props.state.language} hideBadge={this.hideBadge} />
      this.playSuccessAudio();
      fireConfetti();
    }

    let previousButton = '';
    if (this.props.state.badges[2].currentQuestion >= 1) {
      previousButton = <button onClick={() => this.previous()}>{this.state.props.state.language ? 'Anterior' : 'Previous'}</button>
    }

    let nextButton = '';
    if (this.state.showFeedback === true && this.props.state.badges[2].currentQuestion < 3) {
      nextButton = <button onClick={() => this.next()}>{this.state.props.state.language ? 'Siguiente' : 'Next'}</button>
    }

    let results = '';
    if (this.state.badge === true || this.props.state.badges[2].correctAnswers === 4) {
      results = <button className="results" onClick={(event) => this.displayBadge(event)}>{this.state.props.state.language ? 'Insignia obtenida' : 'Obtained Badge'}</button>
    }

    let goSubModule = <button className="goSubModule" onClick={() => this.goSubModule()}>{this.state.props.state.language ? 'Volver al submódulo' : 'Back to submodule'}</button>

    return (

      <div className="page__container">

        <Header submodule="true" changeItem={this.changeItem} />

        {badge}

        <div className="question-content" style={{ backgroundImage: 'url(/images/backgrounds/Tierra.webp)' }}>

          <div className="game-audio-container">
            <div className="background-audio">
              <div className="audio-text__container">
                <p>{this.state.props.state.language ? 'Audio de fondo' : 'Background audio'}</p>
              </div>
              <AudioPlayer
                autoPlay
                loop
                showControls={false}
                showDownloadProgress={false}
                showJumpControls={false}
                customAdditionalControls={[]}
                src='/audios/background/module2/1.mp3'
              />
            </div>
          </div>

          <div className="title">
            <h1>{this.state.currentGame.title}</h1>
          </div>

          {puzzle}
          {image}

          <div id="subgame-feedback" className="feedback-subgame">
            {feedback}
          </div>

          <div className="actions">
            {previousButton}
            {goSubModule}
            {results}
            {nextButton}
          </div>

        </div>

        <Footer />

      </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    badgeObtained: (badges) => {
      dispatch({ type: 'BADGE_OBTAINED', badges });
    },
    addPuzzleGameAnswer: (answers) => {
      dispatch({ type: 'ADD_PUZZLE_GAME_ANSWER', answers });
    },
    changeTotalPuzzleCorrectAnswers: (correctAnswers) => {
      dispatch({ type: 'CHANGE_PUZZLE_GAME_CORRECT_ANSWERS', correctAnswers });
    },
    changePuzzleCurrentQuestion: (currentQuestion) => {
      dispatch({ type: 'CHANGE_PUZZLE_GAME_CURRENT_QUESTION', currentQuestion });
    }
  };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PuzzleSubGame));
