import { Component } from "react";
import { withRouter } from "react-router-dom";
import './Footer.css'
import { connect } from 'react-redux';

class Footer extends Component {

  constructor(props) {
    super();
    this.state = { fullimage: false };
  };

  render() {
    return <div id="Footer" className="footer__container">
      <div className="rights">
        <p>© 2023 Universidad de Costa Rica</p>
      </div>
      <div className="logos">
        <a className="logos-vi" href="https://vinv.ucr.ac.cr/" target="_blank" rel="noreferrer"><img src="/images/logo/VI.png" alt="Vicerrectoría de Investigación" /></a>
        <a className="logos-vas" href="https://accionsocial.ucr.ac.cr/" target="_blank" rel="noreferrer"><img src="/images/logo/VAS.png" alt="Vicerrectoría de Acción Social" /></a>
        <a className="logos-ciicla" href="http://www.ciicla.ucr.ac.cr/" target="_blank" rel="noreferrer"><img src="/images/logo/CIICLA.png" alt="CIICLA" /></a>
        <a className="logos-ojala" href="/" rel="noreferrer"><img src="/images/logo/Ojala.png" alt="Ojalá Ediciones" /></a>
        <a className="logos-faico" href="https://www.cocosisland.org/" target="_blank" rel="noreferrer"><img  src="/images/logo/Faico.png" alt="FAICO" /></a>
        <a className="logos-scj" href="https://www.scjohnson.com/" target="_blank" rel="noreferrer"><img className="logos-scj" src="/images/logo/SCJ.png" alt="SCJohnson" /></a>
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Footer)
);