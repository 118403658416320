import React from 'react';
import './MatchInstructions.css';
import '../PopUp/PopUp.css';
import { withRouter } from 'react-router-dom';
import MatchEsp from '../../content/match-game-instructions-esp.json';
import MatchEng from '../../content/match-game-instructions-eng.json';

function MatchInstructions(props) {

  let instructions;
  if(props.language === 1){
    instructions = MatchEsp;
  }else{
    instructions = MatchEng;
  }

  return (
    <div className="pop-up-container">
      <div className="pop-up">
        <div className="pop-up__header">
          <img
            className="pop-up__close"
            src="/images/icon/close.png"
            onClick={() => props.closeAction()}
            alt="Cerrar dialogo."
          />
        </div>
        <div className="pop-up__info-block">

          <div className="match-title">
            <h1>{instructions[0].title}</h1>
            <h1>{instructions[0].subtitle}</h1>
          </div>

          <div className="left-text">
            <p className="match-instructions">{instructions[0].instructions}</p>
            <ul>
              <li>{instructions[0].first}</li>
              <li>{instructions[0].second}</li>
              <li>{instructions[0].third}</li>
              <li>{instructions[0].fourth}</li>
              <li>{instructions[0].fifth}</li>
            </ul>
          </div>

          <div className="center-panel">
            <button onClick={() => props.startGame()}>{instructions[0].start}</button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default withRouter(MatchInstructions);
