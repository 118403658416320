import React, { Component } from 'react';
import './Game.css';
import '../../utils/ActionsButton/ActionsButton.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../../utils/Header/Header';
import Footer from '../../utils/Footer/Footer';
import GameEsp from '../../content/general-game-esp.json';
import GameEng from '../../content/general-game-eng.json';
import { fireConfetti } from '../../utils/CanvasConfetti/CanvasConfetti'
import AudioPlayer from 'react-h5-audio-player';
import UtilEsp from '../../content/util-esp.json';
import UtilEng from '../../content/util-eng.json';
import { parse } from 'dom-parser-react'

class Game extends Component {

  constructor(props) {
    super();
    this.state = {
      currentQuestionDetailes: {
        question: "",
        options: [],
        userAnswered: false,
        userAnswer: 0,
        correctOption: 0,
        feedback: '',
        feedbackImage: '',
        background: "",
      },
      userAnswered: false,
      userAnsweredCorrect: false,
      userFinished: false,
      props: props
    };
  };

  componentDidMount() {
    this.changeDetailes(this.props.state.badges[0].currentQuestion);
  }

  playSuccessAudio() {
    let successAudio = new Audio('/audios/background/Extras/Success.mp3');
    successAudio.play();
  }

  playErrorAudio() {
    let errorAudio = new Audio('/audios/background/Extras/Error.mp3');
    errorAudio.play();
  }

  changeDetailes(currentQuestion) {
    let language = this.state.props.state.language;
    let game
    if (language === 1) {
      game = GameEsp;
    } else {
      game = GameEng;
    }
    if (currentQuestion !== -1) {
      let filteredQuestion = game.filter(question => question.id === currentQuestion);
      let userAnswered = false;
      let userAnsweredCorrect = false;
      let userAnswer = this.props.state.badges[0].answers.filter(answer => answer.question === currentQuestion);


      if (userAnswer.length >= 1) {
        filteredQuestion[0].userAnswered = true;
        userAnswered = true;
        filteredQuestion[0].userAnswer = userAnswer[0].answer;
        userAnsweredCorrect = userAnswer[0].answer === filteredQuestion[0].correctOption;
      } else {
        filteredQuestion[0].userAnswered = false;
        filteredQuestion[0].userAnswered = -1;
      }

      if (userAnsweredCorrect === true) {
        this.playSuccessAudio();
        fireConfetti();
      } else {
        if (userAnswered) {
          this.playErrorAudio();
        }
      }

      this.setState({ currentQuestionDetailes: filteredQuestion[0], userAnsweredCorrect: userAnsweredCorrect, userAnswered: userAnswered });
    } else {

      let text;
      if (language === 1) {
        text = "¡Felicidades! Usted ha respondido correctamente el total de " + this.props.state.badges[0].correctAnswers + (this.props.state.badges[0].correctAnswers === 1 ? " pregunta " : " preguntas ") + "de 15.";
      } else {
        text = "Congratulations! You answered correctly a total of " + this.props.state.badges[0].correctAnswers + (this.props.state.badges[0].correctAnswers === 1 ? " question " : " questions ") + " out of 15.";
      }

      let emptyQuestion = {
        question: text,
        options: [],
        userAnswered: false,
        userAnswer: 0,
        correctOption: 0,
        feedback: '',
        feedbackImage: '',
        background: "url(/images/backgrounds/origen.webp)",
      }

      this.playSuccessAudio();
      fireConfetti();

      //Assigning Badge;
      let badges = this.state.props.state.badges;
      badges[0].obtained = true;

      if (this.props.state.badges[0].correctAnswers <= 5) {
        badges[0].type = 1;
      } else if (this.props.state.badges[0].correctAnswers > 5 && this.props.state.badges[0].correctAnswers <= 10) {
        badges[0].type = 2;
      } else {
        badges[0].type = 3;
      }
      this.props.badgeObtained(badges);

      this.setState({ currentQuestionDetailes: emptyQuestion, userAnsweredCorrect: false, userAnswered: false });
    }
  }

  previous() {
    let language = this.state.props.state.language;
    let game
    if (language === 1) {
      game = GameEsp;
    } else {
      game = GameEng;
    }
    if (this.props.state.badges[0].currentQuestion > 1 || this.props.state.badges[0].currentQuestion === -1) {
      let previous = this.props.state.badges[0].currentQuestion === -1 ? game.length : this.props.state.badges[0].currentQuestion - 1;
      this.props.changeCurrentQuestion(previous);
      this.changeDetailes(previous);
    }
  }

  next() {
    let language = this.state.props.state.language;
    let game
    if (language === 1) {
      game = GameEsp;
    } else {
      game = GameEng;
    }
    if (this.props.state.badges[0].currentQuestion < game.length && this.props.state.badges[0].currentQuestion !== -1) {
      let next = this.props.state.badges[0].currentQuestion + 1;
      this.props.changeCurrentQuestion(next);
      this.changeDetailes(next);
    }
  }

  results() {
    this.setState({ userFinished: true });
    this.props.changeCurrentQuestion(-1);
    this.changeDetailes(-1);
  }

  goHome() {
    this.state.props.history.push('/');
  }

  goBadgeCenter(){
    this.state.props.history.push('/badgeCenter');
  }

  userAnswered(optionSelected) {
    if ((this.state.userAnswered !== true && this.state.currentQuestionDetailes.userAnswered !== true)) {
      let answers = this.props.state.badges[0].answers;
      answers.push({ 'question': this.props.state.badges[0].currentQuestion, 'answer': optionSelected });
      let userAnsweredCorrect = optionSelected === this.state.currentQuestionDetailes.correctOption;

      let correctAnswers = this.props.state.badges[0].correctAnswers;

      if (userAnsweredCorrect === true) {
        this.playSuccessAudio();
        fireConfetti();
        correctAnswers = correctAnswers + 1;
      } else {
        this.playErrorAudio();
      }
      this.setState({ userAnswered: true, userAnsweredCorrect: userAnsweredCorrect });
      this.props.addGeneralGameAnswer(answers);
      this.props.changeTotalCorrectAnswers(correctAnswers);
    }
  }

  render() {

    let language = this.state.props.state.language;
    let util;
    let game
    if (language === 1) {
      util = UtilEsp;
      game = GameEsp;
    } else {
      util = UtilEng;
      game = GameEng;
    }

    let title = "Trivia";
    if (this.state.userFinished === true && this.props.state.badges[0].currentQuestion === -1) {

      if (language === 1) {
        title = "Resultados de la trivia"
      } else {
        title = "Trivia results"
      }
    }

    let feedback = '';
    if (this.state.userAnswered === true || this.state.currentQuestionDetailes.userAnswered === true) {
      feedback =
        <div className="feedback">
          <h1>{this.state.userAnsweredCorrect === true ? language === 1 ? 'Correcto' : 'Correct' : language === 1 ? 'Incorrecto' : 'Incorrect'}</h1>
          <p>{parse(this.state.currentQuestionDetailes.feedback, {
            createElement: React.createElement,
            Fragment: React.Fragment,
          })}</p>
          <img className="feedback-image" src={"/images/generalGame/" + this.state.currentQuestionDetailes.feedbackImage} alt="related" />
          <p>{parse(this.state.currentQuestionDetailes.imageCredits, {
            createElement: React.createElement,
            Fragment: React.Fragment,
          })}</p>
        </div>;
      document.getElementById('Footer')?.scrollIntoView({ alignToTop: false, behavior: 'smooth' });
    } else {
      document.getElementById('Header')?.scrollIntoView({ behavior: 'smooth' });

    }

    let previousButton = '';
    if (this.props.state.badges[0].currentQuestion > 1 || this.props.state.badges[0].currentQuestion === -1) {
      previousButton = <button onClick={() => this.previous()}>{language === 1 ? 'Anterior' : 'Previous'}</button>
    }

    let nextButton = '';
    if (this.state.userAnswered === true && this.props.state.badges[0].currentQuestion < game.length) {
      nextButton = <button onClick={() => this.next()}>{language === 1 ? 'Siguiente' : 'Next'}</button>
    }

    let results = '';
    if (this.state.userAnswered === true && this.props.state.badges[0].currentQuestion === game.length && this.props.state.badges[0].currentQuestion !== -1) {
      results = <button className="results" onClick={() => this.results()}>{language === 1 ? 'Resultados' : 'Results'}</button>
    }

    let badgeCenter = '';
    if (this.props.state.badges[0].currentQuestion === -1) {
      badgeCenter = <button onClick={() => this.goBadgeCenter()}>{language === 1 ? 'Insignias obtenidas' : 'Badge center'}</button>
    }

    let result = '';
    if ((this.state.userFinished === true && this.props.state.badges[0].currentQuestion === -1) || (this.props.state.badges[0].obtained === true && this.props.state.badges[0].currentQuestion === -1)) {

      let badgeToDisplay = '';
      let textToDisplay = '';

      let firstTextToDisplayEsp = "Ha obtenido la Insignia Travesia por la Isla del Coco con ";
      let secondTextToDisplayEsp = "";
      let firstTextToDisplayEng = "You obtained the badge Journey Through Cocos Island with ";
      let secondTextToDisplayEng = "";

      if (this.props.state.badges[0].correctAnswers <= 5) {
        //badge 1
        if (language === 1) {
          title = firstTextToDisplayEsp + "1 estrella " + secondTextToDisplayEsp;
          badgeToDisplay = 'Insignia1.png';
        } else {
          title = firstTextToDisplayEng + "1 star " + secondTextToDisplayEng;
          badgeToDisplay = 'Insignia1-eng.png';
        }
        //linkToShare = 'test';
      } else if (this.props.state.badges[0].correctAnswers > 5 && this.props.state.badges[0].correctAnswers <= 10) {
        //badge 2
        if (language === 1) {
          title = firstTextToDisplayEsp + "2 estrellas " + secondTextToDisplayEsp;
          badgeToDisplay = 'Insignia2.png';
        } else {
          title = firstTextToDisplayEng + "2 stars " + secondTextToDisplayEng;
          badgeToDisplay = 'Insignia2-eng.png';
        }
      } else {
        //badge 3
        if (language === 1) {
          title = firstTextToDisplayEsp + "3 estrellas " + secondTextToDisplayEsp;
          badgeToDisplay = 'Insignia3.png';
        } else {
          title = firstTextToDisplayEng + "3 stars " + secondTextToDisplayEng;
          badgeToDisplay = 'Insignia3-eng.png';
        }
        //linkToShare = 'test';
      }

      result =
        <div className="results">
          <div className="results_badge">
            <img src={"/images/generalGame/" + badgeToDisplay} alt="Insignia obtenida" />
          </div>
          <div className="results_description">
            <p>{textToDisplay}</p>
          </div>
        </div>;
    }

    let goHome = '';
    if (this.props.state.badges[0].currentQuestion === -1) {
      goHome = <button className="goHome" onClick={() => this.goHome()}>{language === 1 ? 'Página de inicio' : 'Home page'}</button>
    }


    return (

      <div className="page__container">

        <Header submodule="true" />

        <div className="question-content" style={{ backgroundImage: this.state.currentQuestionDetailes.background }}>

          <div className="game-audio-container">
            <div className="background-audio">
              <div className="audio-text__container">
                <p>{util[0].backgroundAudio}</p>
              </div>
              <AudioPlayer
                autoPlay
                loop
                showControls={false}
                showDownloadProgress={false}
                showJumpControls={false}
                customAdditionalControls={[]}
                src='/audios/background/Extras/1.mp3'
              />
            </div>
          </div>

          <div className="title-game">
            <h1>{title}</h1>
          </div>

          <div className="question">
            <p>{this.state.currentQuestionDetailes.question}</p>
          </div>

          <div className="options">

            {
              this.state.currentQuestionDetailes.options.map((option, index) => {
                return (
                  <button key={option + '-' + index} className={(this.state.userAnswered === true && this.state.currentQuestionDetailes.userAnswer === option) ? 'selected' : 'no-selected'} onClick={() => this.userAnswered(option.id)}>
                    <span className="flexible" >{parse(option.description, {
                      createElement: React.createElement,
                      Fragment: React.Fragment,
                    })}</span>
                    <span></span>
                  </button>
                )
              })
            }
          </div>

          {feedback}

          {result}

          <div className="actions">
            {previousButton}
            {nextButton}
            {results}
            {badgeCenter}
            {goHome}
          </div>

        </div>

        <Footer />

      </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    badgeObtained: (badges) => {
      dispatch({ type: 'BADGE_OBTAINED', badges });
    },
    addGeneralGameAnswer: (answers) => {
      dispatch({ type: 'ADD_GENERAL_GAME_ANSWER', answers });
    },
    changeTotalCorrectAnswers: (correctAnswers) => {
      dispatch({ type: 'CHANGE_GENERAL_GAME_CORRECT_ANSWERS', correctAnswers });
    },
    changeCurrentQuestion: (currentQuestion) => {
      dispatch({ type: 'CHANGE_GENERAL_GAME_CURRENT_QUESTION', currentQuestion });
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Game));
