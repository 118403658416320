import React from 'react';
import './MemorySubGameFeedback.css';
import '../../../../utils/PopUp/PopUp.css';
import { withRouter } from 'react-router-dom';

function MemorySubGameFeedback(props) {


  let language = props.language;

  return (
    <div className="pop-up-container">
      <div className="pop-up">
        <div className="pop-up__header">
          <img
            className="pop-up__close"
            src="/images/icon/close.png"
            onClick={() => props.hideFeedback()}
            alt="Cerrar dialogo."
          />
        </div>
        <div className="pop-up__info-block">

          <div>
            <h1 className="memory-feedback-title">{language === 1 ? '¡Correcto!' : 'Correct!'}</h1>
          </div>

          <div className="left-text">
            <p>{props.feedback}</p>
          </div>

          <div className="center-panel">
            <button onClick={() => props.hideFeedback()}>{language === 1 ? 'Cerrar' : 'Close'}</button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default withRouter(MemorySubGameFeedback);
