import React from 'react';
import './PuzzleSubGameBadge.css';
import '../../../../utils/PopUp/PopUp.css';
import { withRouter } from 'react-router-dom';

function PuzzleSubGameBadge(props) {

  let language = props.language;
  let textToDisplay;
  let badgeToDisplay = '';

  if (language === 1) {
    textToDisplay = "Ha completado el juego.";
    badgeToDisplay = 'tierra.png';
  } else {
    textToDisplay = "You have completed the game."
    badgeToDisplay = 'tierra-eng.png';
  }


  let image = <img className="puzzle_badge" src={"/images/subGame/" + badgeToDisplay} alt="Insignia obtenida" />

  return (
    <div className="pop-up-container">
      <div className="pop-up">
        <div className="pop-up__header">
          <img
            className="pop-up__close"
            src="/images/icon/close.png"
            onClick={() => props.hideBadge()}
            alt="Cerrar dialogo."
          />
        </div>
        <div className="pop-up__info-block--center">
          <h1 className="puzzle-badge-title">{language === 1 ? '¡Felicidades!' : 'Congratulations!'}</h1>
          {image}
          <p className="puzzle-results_description">{textToDisplay}</p>
          <button className="badge-center-link" onClick={() => props.history.push('/badgeCenter')}>{language === 1 ? 'Insignias obtenidas' : 'Badge center'}</button>
        </div>

      </div>
    </div>
  );
}

export default withRouter(PuzzleSubGameBadge);
