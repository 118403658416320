import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { compose, createStore } from 'redux';
import * as ReactRedux from 'react-redux';
import persistState from 'redux-sessionstorage'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollTop from './utils/ScrollTop/ScrollTop';
import Home from './web-pages/Home/Home';
import SubModule from './web-pages/SubModule/SubModule';
import Game from './web-pages/Game/Game';
import './fonts/TruenoLt.otf';
import './fonts/TruenoRg.otf';
import PuzzleSubGame from './web-pages/SubGame/PuzzleGame/PuzzleSubGame';
import MemorySubGame from './web-pages/SubGame/MemoryGame/MemorySubGame';
import BadgeCenter from './web-pages/BadgeCenter/BadgeCenter';
import DragDropSubGame from './web-pages/SubGame/DragDropGame/DragDropSubGame';
import QuestionsSubGame from './web-pages/SubGame/QuestionsGame/QuestionsSubGame';
import Credits from './web-pages/Credits/Credits';
import References from './web-pages/References/References';
import Visits from './web-pages/Visits/Visits';

let newState = {};
let currentSubModule = 1;
let language = 1;
let visitInfoObteined = 0;
let badges = [{
  id: 0,
  obtained: false,
  type: 1,
  currentQuestion: 1,
  correctAnswers: 0,
  answers: [],
  description: "General game",
  image: "/generalGame/Insignia"
},
{
  id: 1,
  obtained: false,
  description: "Origin",
  image: "/subGame/origen.png",
  imageEng: "/subGame/origen-eng.png"
},
{
  id: 2,
  obtained: false,
  currentQuestion: 0,
  correctAnswers: 0,
  answers: [],
  description: "Earth",
  image: "/subGame/tierra.png",
  imageEng: "/subGame/tierra-eng.png"
},
{
  id: 3,
  obtained: false,
  currentQuestion: 1,
  correctAnswers: 0,
  answers: [],
  description: "Ocean",
  image: "/subGame/mar.png",
  imageEng: "/subGame/mar-eng.png"
},
{
  id: 4,
  obtained: false,
  description: "Threats",
  image: "/subGame/amenazas.png",
  imageEng: "/subGame/amenazas-eng.png"
}];

function reducer(state = { currentSubModule, badges, language, visitInfoObteined}, action) {
  switch (action.type) {
    case 'CHANGE_CURRENT_SUBMODULE':
      newState = state;
      newState.currentSubModule = action.currentSubModule;
      return Object.assign(
        {},
        state, newState
      );
    case 'BADGE_OBTAINED':
      newState = state;
      newState.badges = action.badges;
      return Object.assign(
        {},
        state, newState
      );
    case 'CHANGE_LANGUAGE':
      newState = state;
      newState.language = action.language;
      return Object.assign(
        {},
        state, newState
      );
    case 'ADD_GENERAL_GAME_ANSWER':
      newState = state;
      newState.badges[0].answers = action.answers;
      return Object.assign(
        {},
        state, newState
      );
    case 'CHANGE_GENERAL_GAME_CORRECT_ANSWERS':
      newState = state;
      newState.badges[0].correctAnswers = action.correctAnswers;
      return Object.assign(
        {},
        state, newState
      );
    case 'CHANGE_GENERAL_GAME_CURRENT_QUESTION':
      newState = state;
      newState.badges[0].currentQuestion = action.currentQuestion;
      return Object.assign(
        {},
        state, newState
      );
    case 'ADD_SEA_GAME_ANSWER':
      newState = state;
      newState.badges[3].answers = action.answers;
      return Object.assign(
        {},
        state, newState
      );
    case 'CHANGE_SEA_GAME_CORRECT_ANSWERS':
      newState = state;
      newState.badges[3].correctAnswers = action.correctAnswers;
      return Object.assign(
        {},
        state, newState
      );
    case 'CHANGE_SEA_GAME_CURRENT_QUESTION':
      newState = state;
      newState.badges[3].currentQuestion = action.currentQuestion;
      return Object.assign(
        {},
        state, newState
      );
    case 'ADD_PUZZLE_GAME_ANSWER':
      newState = state;
      newState.badges[2].answers = action.answers;
      return Object.assign(
        {},
        state, newState
      );
    case 'CHANGE_PUZZLE_GAME_CORRECT_ANSWERS':
      newState = state;
      newState.badges[2].correctAnswers = action.correctAnswers;
      return Object.assign(
        {},
        state, newState
      );
    case 'CHANGE_PUZZLE_GAME_CURRENT_QUESTION':
      newState = state;
      newState.badges[2].currentQuestion = action.currentQuestion;
      return Object.assign(
        {},
        state, newState
      );
    case 'CHANGE_VISIT_INFO_OBTEINED':
      newState = state;
      newState.visitInfoObteined = 1;
      return Object.assign(
        {},
        state, newState
      );
    default:
      return state;
  }
}

const createPersistentStore = compose(
  persistState()
)(createStore)

const store = createPersistentStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

window.addEventListener("contextmenu", e => e.preventDefault());

ReactDOM.render(
  <BrowserRouter>
    <ScrollTop>
      <ReactRedux.Provider store={store}>
        <React.Fragment>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/submodule/:submodule" component={SubModule} />
            <Route exact path="/generalGame" component={Game} />
            <Route exact path="/badgeCenter" component={BadgeCenter} />
            <Route exact path="/credits" component={Credits} />
            <Route exact path="/visits" component={Visits} />
            <Route exact path="/references" component={References} />
            <Route exact path="/submodule/:submodule/puzzle-game" component={PuzzleSubGame} />
            <Route exact path="/submodule/:submodule/memory-game" component={MemorySubGame} />
            <Route exact path="/submodule/:submodule/match-game" component={DragDropSubGame} />
            <Route exact path="/submodule/:submodule/question-game" component={QuestionsSubGame} />
            <Route component={Home} />
          </Switch>
        </React.Fragment>
      </ReactRedux.Provider>
    </ScrollTop>
  </BrowserRouter>,
  document.getElementById('root')
);
