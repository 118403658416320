import React from 'react';
import './DragDropSubGameFeedback.css';
import '../../../../utils/PopUp/PopUp.css';
import { withRouter } from 'react-router-dom';

function DragDropSubGameFeedback(props) {

  return (
    <div className="pop-up-container">
      <div className="pop-up">
        <div className="pop-up__header">
          <img
            className="pop-up__close"
            src="/images/icon/close.png"
            onClick={() => props.hideFeedback()}
            alt={props.language === 1 ? 'Cerrar' : 'Close'}
          />
        </div>
        <div className="pop-up__info-block">

          <div>
            <h1 className="match-feedback-title">{props.language === 1 ? '¡Correcto!' : 'Correct!'}</h1>
          </div>

          <div className="left-text">
            <p>{props.feedback}</p>
          </div>

          <div className="center-panel">
            <button onClick={() => props.hideFeedback()}>{props.language === 1 ? 'Cerrar' : 'Close'}</button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default withRouter(DragDropSubGameFeedback);
