import React, { Component } from 'react';
import './DragDropSubGame.css';
import '../../../utils/ActionsButton/ActionsButton.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../../../utils/Header/Header';
import Footer from '../../../utils/Footer/Footer';
import DragList from "./DragList";
import AudioPlayer from 'react-h5-audio-player';
import DragDropSubGameBadge from './DragDropSubGameBadge/DragDropSubGameBadge'

class DragDropSubGame extends Component {

  constructor(props) {
    super();
    this.state = {
      currentSubModule: 1,
      showBadge: false,
      props: props
    };
    this.changeItem = this.changeItem.bind(this);
    this.displayBadge = this.displayBadge.bind(this);
    this.hideBadge = this.hideBadge.bind(this);
  };

  componentDidMount() {
    this.setState({ currentSubModule: parseInt(this.props.match.params.submodule) });
  }

  goSubModule() {
    this.props.history.push('/submodule/' + this.state.currentSubModule);
  }

  changeItem(item) {
    this.props.history.push('/submodule/' + item);
  }

  displayBadge(event){
    event.preventDefault();
    this.setState({ showBadge: true });
  }

  hideBadge(){
    this.setState({ showBadge: false });
  }

  render() {

    let results = '';
    if (this.state.props.state.badges[4].obtained === true) {
      results = <button className="results" onClick={(event) => this.displayBadge(event)}>{this.props.state.language === 1 ? 'Insignia obtenida' : 'Badge obtained'}</button>
    }

    let badge = '';
    if (this.state.showBadge === true) {
      badge = <DragDropSubGameBadge language={this.props.state.language} hideBadge={this.hideBadge} />
    }

    return (


      <div className="page__container">

        <Header submodule="true" changeItem={this.changeItem} />

        {badge}

        <div className="question-content" style={{ backgroundImage: 'url(/images/backgrounds/Amenazas.webp)' }}>

          <div className="game-audio-container">
            <div className="background-audio">
              <div className="audio-text__container">
                <p>{this.props.state.language === 1 ? 'Audio de fondo' : 'Background audio'}</p>
              </div>
              <AudioPlayer
                autoPlay
                loop
                showControls={false}
                showDownloadProgress={false}
                showJumpControls={false}
                customAdditionalControls={[]}
                src='/audios/background/module4/2.mp3'
              />
            </div>
          </div>

          <div className="draggame-title">
            <h1>{this.props.state.language === 1 ? 'Asocie' : 'Matching Pairs'}</h1>
            <h2>{this.props.state.language === 1 ? 'Arrastre las imagenes a su problemática correspondiente.' : 'Drag and drop the images to their related threat.'}</h2>
          </div>

          <div className="draggame">

            <DragList language={this.props.state.language} />;

          </div>

          <div className="actions">
            <button className="goSubModule" onClick={() => this.goSubModule()}>{this.props.state.language === 1 ? 'Volver al submódulo' : 'Back to Submodule'}</button>
            {results}
          </div>

        </div>

        <Footer />

      </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default withRouter(connect(mapStateToProps, null)(DragDropSubGame));
