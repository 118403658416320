import React from 'react';
import './DragDropSubGameBadge.css';
import '../../../../utils/PopUp/PopUp.css';
import { withRouter } from 'react-router-dom';

function DragDropSubGameBadge(props) {



  let badgeToDisplay = '';
  let language = props.language;
  let textToDisplay;
  if (language === 1) {
    textToDisplay = "Ha completado el juego.";
    badgeToDisplay = 'amenazas.png';
  } else {
    textToDisplay = "You have completed the game."
    badgeToDisplay = 'amenazas-eng.png';
  }

  return (
    <div className="pop-up-container">
      <div className="pop-up">
        <div className="pop-up__header">
          <img
            className="pop-up__close"
            src="/images/icon/close.png"
            onClick={() => props.hideBadge()}
            alt="Cerrar dialogo."
          />
        </div>

        <div className="pop-up__info-block--center">
          <h1 className="memory-badge-title">{props.language === 1 ? 'Felicidades!' : 'Congratulations!'}</h1>
          <img className="memory_badge" src={"/images/subGame/" + badgeToDisplay} alt={props.language === 1 ? 'insignia obtenida' : 'badge obtained!'} />
          <p className="memory-results_description">{textToDisplay}</p>
          <button className="badge-center-link" onClick={() => props.history.push('/badgeCenter')}>{language === 1 ? 'Insignias obtenidas' : 'Badge center'}</button>
        </div>

      </div>
    </div>
  );
}

export default withRouter(DragDropSubGameBadge);
