import React, { Component } from 'react';
import './Credits.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Footer from '../../utils/Footer/Footer';
import Header from '../../utils/Header/Header';
import CreditsEsp from '../../content/credits-esp.json';
import CreditsEng from '../../content/credits-eng.json';
import AudioPlayer from 'react-h5-audio-player';
import UtilEsp from '../../content/util-esp.json';
import UtilEng from '../../content/util-eng.json';

class Credits extends Component {
  constructor(props) {
    super();
    this.state = {
      props: props
    };
  }

  render() {

    let creditsText = '';
    let util;
    if (this.props.state.language === 1) {
      creditsText = CreditsEsp;
      util = UtilEsp;
    } else {
      creditsText = CreditsEng;
      util = UtilEng;
    }

    return (
      <div className="module__container" >

        <div className="page__content">

          <Header submodule="true" />

          <div className="credits-content" style={{ backgroundImage: 'url(/images/backgrounds/Origen.webp)' }}>

            <div className="game-audio-container">
              <div className="background-audio">
                <div className="audio-text__container">
                  <p>{util[0].backgroundAudio}</p>
                </div>
                <AudioPlayer
                  autoPlay
                  loop
                  volume="1"
                  showControls={false}
                  showDownloadProgress={false}
                  showJumpControls={false}
                  customAdditionalControls={[]}
                  src='/audios/background/Extras/2.mp3'
                />
              </div>
            </div>

            <div className="credits-center_top">
              <h1 className="credits-center__title">{creditsText[0].title}</h1>
            </div>

            <div className="credits">

              <h2>{creditsText[0].production}</h2>
              <h3>{creditsText[0].coordinators}</h3>
              <p>Jorge Cortés Núñez, Centro de Investigación en Ciencias del Mar y Limnología (CIMAR), Universidad de Costa Rica</p>
              <p>Eugenia Zavaleta Ochoa, Museo de la Universidad de Costa Rica (Museo UCR)</p>
              <h3>{creditsText[0].exhibitionCuratorship}</h3>
              <p>Kimberly García Méndez, CIMAR, Universidad de Costa Rica</p>
              <p>Jorge Cortés Núñez, CIMAR, Universidad de Costa Rica</p>
              <h3>{creditsText[0].design}</h3>
              <p>Mariano Javier Chinchilla Chavarría, Museo UCR</p>
              <h3>{creditsText[0].educationCuratorship}</h3>
              <p>Adriana Araya Góchez, Museo UCR</p>
              <h3>{creditsText[0].editing}</h3>
              <p>Marco Díaz Segura, Museo UCR</p>
              <p>Beatriz Naranjo Elizondo, CIMAR</p>
              <h3>{creditsText[0].photoCompiler}</h3>
              <p>Luciano Capelli, Ojalá Ediciones</p>
              <h3>{creditsText[0].animation}</h3>
              <p>Edgar Sandí Martínez, Museo UCR</p>
              <h3>{creditsText[0].translation}</h3>
              <p>Jeanina Umaña Aguilar</p>
              <p>Luciana Pavez Phillips</p>
              <p>Jorge Cortés Núñez, CIMAR</p>
              <p>Félix Barboza Retana, Museo UCR</p>
              <h3>{creditsText[0].proofreading}</h3>
              <p>Grettel Hernández Valdés</p>
              <h3>{creditsText[0].development}</h3>
              <p><a href='mailto:jorgeremonm@gmail.com?subject=Contact Cocos Island Code Support&body=Atlantis Code contact for any code support.'>Jorge Remón Montealegre</a>, <a href="https://www.linkedin.com/company/atlantiscode/" target="_blank" rel="noreferrer">Atlantis Code</a></p>


              <h2>{creditsText[0].acknowledgments}</h2>
              <p>Área de Conservación Marina Coco</p>
              <h3>{creditsText[0].scientificSupport}</h3>
              <p>Luis Guillermo Acosta, Instituto Tecnológico de Costa Rica</p>
              <p>Guillermo Alvarado Induni, Instituto Costarricense de Electricidad </p>
              <p>Rolando Bastida-Zavala, Universidad del Mar, Oaxaca, México </p>
              <p>Ernesto Carman, Get-your Birds Tours, Costa Rica</p>
              <p>Marco Corrales, Universidad de Oregon, EUA</p>
              <p>Rade Garic, Universidad de Dubrovnik, Croácia</p>
              <p>Pablo Gutiérrez, Universidad de Costa Rica</p>
              <p>Michel E. Hendrickx, Universidad Nacional Autónoma de México</p>
              <p>Harilaos Lessios, Smithsonian Tropical Research Institute, Panamá</p>
              <p>Laurence P. Madin, Woods Hole Oceanographic Institution, EUA</p>
              <p>Laura May-Collado, Universidad de Vermont, EUA</p>
              <p>Claudia Mills, Universidad de Washington, EUA</p>
              <p>Michel Montoya, investigador independiente</p>
              <p>José David Palacios, Asociación Keto, Costa Rica</p>
              <p>Ross Robertson, Smithsonian Tropical Research Institute, Panamá </p>
              <p>Alexander Rojas, Universidad Nacional de Costa Rica</p>
              <p>Erick Ross, MarViva, Costa Rica</p>
              <p>Francisco Solís-Marín, Universidad Nacional Autónoma de México </p>
              <p>María Tovar-Hernández, Universidad Autónoma de Nuevo León, México</p>
              <p>Rob van Soest, Universidad de Amsterdam, Holanda</p>
              <p>Juan Diego Vargas, Get-your Birds Tours, Costa Rica</p>
              <h3>{creditsText[0].ucr}</h3>
              <p>Erick J. Alfaro, CIGEFI</p>
              <p>Arturo Angulo, Museo de Zoología</p>
              <p>Juan Carlos Azofeifa, CIMAR</p>
              <p>Mario Blanco, Jardín Botánico Lankester </p>
              <p>Diego Bogarín, Jardín Botánico Lankester </p>
              <p>Federico Bolaños, Escuela de Biología</p>
              <p>Odalisca Breedy, Escuela de Biología, CIEMic y CIMAR </p>
              <p>Cindy Fernández García, Escuela de Biología y CIMAR </p>
              <p>Omar Lizano, CIMAR</p>
              <p>Beatriz Naranjo Elizondo, CIMAR</p>
              <p>Jeffrey Sibaja Cordero, Escuela de Biología y CIMAR</p>
              <h3>{creditsText[0].photographsVideos}</h3>
              <p>Luciano Capelli, Ojalá Ediciones</p>
              <p>Cristiano Paoli, Seamasters Costa Rica</p>
              <p>Pepe Manzanilla, Ojalá Ediciones</p>
              <p>Shmulik Blum, Undersea Hunter Group</p>
              <p>Enric Sala, Pristine Seas</p>
              <p>Avi Klapfer, Undersea Hunter Group</p>
              <p>Carlos Manuel Uribe, FAICO </p>
              <p>Edwar Herreño</p>
              <p>Felipe López Pozuelo </p>
              <p>Frank Baensch </p>
              <p>Diego Bogarín, Jardín Botánico Lankester</p>
              <p>Kip Evans, Mission Blue </p>
              <p>David García </p>
              <p>Pablo Gutiérrez, Universidad de Costa Rica</p>
              <p>Diego Mejías</p>
              <p>Alberto Muñoz, Okeanos Aggressor</p>
              <p>Genna Robustelli, Undersea Hunter Group</p>
              <p>Yanina Rovinsky</p>
              <p>Nico Ghersinich, Seamasters Costa Rica</p>
              <p>Sean Davis</p>
              <p>Luis Sánchez, Rango Extendido</p>
              <p>Federico Pochet, Undersea Hunter Group</p>
              <p>Guillermo Blanco, Parque Nacional Isla del Coco</p>
              <h3>Museo de la Universidad de Costa Rica (Museo UCR)</h3>
              <p>Eugenia Zavaleta Ochoa</p>
              <p>Félix Barboza Retana</p>
              <p>Laura Mariana Raabe Cercone y Yojina Mora Quirós</p>
              <p>Marco Díaz Segura</p>
              <p>Adriana Araya Góchez</p>
              <p>Diseño Gráfico: Mariano Javier Chinchilla Chavarría</p>
              <p>Voluntarios / Volunteers</p>
              <p>Alicia Granados Granados</p>
              <p>Jacobo Valerín Araya</p>
              <h3>Centro de Investigación en Ciencias del Mar y Limnología (CIMAR)</h3>
              <p>Director: Álvaro Morales Ramírez</p>
              <p>Subdirector: Eddy Gómez</p>
              <h3>Centro de Investigación en Identidad y Cultura Latinoamericanas (CIICLA)</h3>
              <p>{creditsText[0].ciiclaDir}</p>
              <p>{creditsText[0].ciiclaSubDir}</p>

              <h2>{creditsText[0].organizers}</h2>
              <img title="CIMAR" className="cimar_custom_logo" src="/images/logo/cimar-custom-logo.png" alt="CIMAR" />
              <p>{creditsText[0].cimar}</p>

              <img title="Museo UCR" className="museo_custom_logo" src="/images/logo/museo-custom-logo.png" alt="museo UCR" />
              <p>{creditsText[0].museo}</p>

              <h2>{creditsText[0].funding}</h2>
              <img title="FAICO" className="faico_custom_logo" src="/images/logo/faico-custom-logo.png" alt="FAICO" />
              <p>{creditsText[0].faico}</p>

              <img title="SCJohnson" className="SCJohnson_custom_logo" src="/images/logo/scjohnson-custom-logo.png" alt="SCJohnson" />
              <p>{creditsText[0].faico}</p>

              <img title="Academia de Ciencias Costa Rica" className="cienciascr_custom_logo" src="/images/logo/cienciascr-custom-logo.png" alt="Academia de Ciencias Costa Rica" />
              <p>{creditsText[0].cienciasCr}</p>

            </div>

            <div className="credits-logos">
              <img title="Universidad de Costa Rica" className="ucr_custom_logo" src="/images/logo/ucr-custom-logo.png" alt="Universidad de Costa Rica" />
              <img title="Vicerrectoria de Acción Social - UCR" className="vas_custom_logo" src="/images/logo/vas-custom-logo.png" alt="Vicerrectoria de Acción Social - UCR" />
              <img title="Vicerrectoria de Investigación - UCR" className="vi_custom_logo" src="/images/logo/vi-custom-logo.png" alt="Vicerrectoria de Investigación - UCR" />
            </div>

            <div className="credits-logos-second">

              <img title="CIMAR" className="cimar_custom_logo" src="/images/logo/cimar-custom-logo.png" alt="CIMAR" />
              <img title="Museo UCR" className="museo_custom_logo" src="/images/logo/museo-custom-logo.png" alt="museo UCR" />
              <img title="Vicerrectoria de Investigación - UCR" className="ciicla_custom_logo" src="/images/logo/ciicla.svg" alt="Vicerrectoria de Investigación - UCR" />

            </div>

          </div>

          <Footer />

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default withRouter(connect(mapStateToProps, null)(Credits));
