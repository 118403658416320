import React from 'react';
import './Toolbar.css';
import { withRouter } from 'react-router-dom';

function Toolbar(props) {

  return (
    <div className="toolbar-container">

      <div className="navigation-container">
        <div className="navigation-container__left-arrow" onClick={(event) => props.previous(event)}>
          <img
            className="left-arrow__image"
            src={'/images/icon/left-arrow.png'}
            alt="submódulo anterior."
          />
          <p>{props.language === 1 ? 'Anterior' : 'Previous'}</p>
        </div>
        <p> | </p>
        <div className="navigation-container__right-arrow" onClick={(event) => props.next(event)}>
        <p>{props.language === 1 ? 'Siguiente' : 'Next'}</p>
          <img
            className="right-arrow__image"
            src={'/images/icon/right-arrow.png'}
            alt="submódulo siguiente."
          />
        </div>

      </div>

    </div>
  );
}

export default withRouter(Toolbar);
