import React, { Component } from 'react';
import './BadgeCenter.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Footer from '../../utils/Footer/Footer';
import Header from '../../utils/Header/Header';
import AudioPlayer from 'react-h5-audio-player';
import UtilEsp from '../../content/util-esp.json';
import UtilEng from '../../content/util-eng.json';

class BadgeCenter extends Component {

  constructor(props) {
    super();
    this.state = {
      props: props
    };
  }


  render() {

    let language = this.state.props.state.language;
    let util;
    if (language === 1){
      util = UtilEsp;
    } else{
      util = UtilEng;
    }

    return (
      <div className="badge-center__container" >

        <div className="badge-center__content">

          <Header submodule="true" />

          <div className="question-content" style={{ backgroundImage: 'url(/images/backgrounds/Amenazas.webp)' }}>

            <div className="game-audio-container">
              <div className="background-audio">
                <div className="audio-text__container">
                  <p>{util[0].backgroundAudio}</p>
                </div>
                <AudioPlayer
                  autoPlay
                  loop
                  showControls={false}
                  showDownloadProgress={false}
                  showJumpControls={false}
                  customAdditionalControls={[]}
                  src='/audios/background/Extras/1.mp3'
                />
              </div>
            </div>


            <div className="badge-center_top">
              <h1 className="badge-center__title">{util[0].badgeCenterTitle}</h1>
              <h1 className="badge-center__subtitle">{util[0].badgeCenterDescription}</h1>
            </div>


            <div className='badge-container'>

              {
                this.state.props.state.badges.map((badge, index) => {
                  if (badge.obtained === true) {
                    if (badge.description === "General game") {
                      return (
                        <div className='badge-card' key={index}>
                          <img className="game_badge" src={"/images" + badge.image + badge.type + (this.props.state.language === 1 ? '.png' : '-eng.png')} alt={badge.description} />
                        </div>
                      )
                    } else {
                      return (
                        <div className='badge-card' key={index}>
                          <img className="game_badge" src={"/images" + (this.props.state.language === 1 ? badge.image : badge.imageEng)} alt={badge.description} />
                        </div>
                      )
                    }
                  } else {
                    return (
                      <div className='badge-card' key={index}>
                        <img className="game_badge" src={"/images/logo/logo" + (this.props.state.language === 1 ? '-esp' : '-eng') + '.png' } alt="Badge pending" />
                      </div>
                    )
                  }
                })
              }

            </div>

          </div>

          <Footer />

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default withRouter(connect(mapStateToProps, null)(BadgeCenter));
