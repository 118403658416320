import React, { Component } from 'react';
import './Header.css';
import { connect } from 'react-redux';
import Menu from '../Menu/Menu';
import { withRouter } from 'react-router-dom';
import IntroductionEsp from '../../content/Home-introduction-esp.json';
import IntroductionEng from '../../content/Home-introduction-eng.json';
import { parse } from 'dom-parser-react'

class Header extends Component {

  constructor(props) {
    super();
    this.state = { text: null, title: props.title, subtitle: props.subtitle };
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  render() {
    let menu = '';
    if (this.state.menu) {
      menu = <Menu closeAction={this.closeMenu} isSubmodule={this.props.submodule} changeItem={this.props.changeItem} />;
    }

    let isSubmodule = false;
    if (this.props.submodule === 'true') {
      isSubmodule = true;
    };

    let classValue = (isSubmodule) ? "header__main-image--submodule" : "header__main-image";

    let introductionText = "";
    let homeSlogan = "";

    if (this.props.state.language === 1) {
      introductionText = IntroductionEsp;
    } else {
      introductionText = IntroductionEng;
    }

    introductionText.map(record => {
      homeSlogan = parse(record.homeSlogan, {
        createElement: React.createElement,
        Fragment: React.Fragment,
      });
      return true;
    })

    return (
      <header id="Header">
        {menu}

        <div className="header">
          <a className="logo-ucr" href="https://www.ucr.ac.cr" target="_blank" rel="noreferrer"><img src="/images/logo/ucr.png" alt="UCR" /> </a>
          <div className="secondary-logo">
            <a className="logo-mucr" href="http://www.museo.ucr.ac.cr/" target="_blank" rel="noreferrer"><img src="/images/logo/mucr.png" alt="Museo UCR" /> </a>
            <a className="logo-cimar" href="https://cimar.ucr.ac.cr/" target="_blank" rel="noreferrer"><img src="/images/logo/cimar.png" alt="CIMAR" /> </a>
          </div>
        </div>

        <div className="header__controls">
          <div className="header__menu" >
            <h3>
              <a href="/"><img title="Home" className="home__icon" alt="home" src="/images/icon/home.png" /></a>
              {homeSlogan}
              </h3>
            <img title="Menu" className="menu__icon" onClick={(event) => this.openMenu(event)} src="/images/icon/menu.png" alt="menu" />
          </div>
          <div className={classValue} style={{ backgroundImage: `url(/images/backgrounds/Isla.png)` }}>
          </div>
        </div>

      </header >
    );
  }

  openMenu(event) {
    event.preventDefault();
    this.setState({ menu: true });
  }

  closeMenu() {
    this.setState({ menu: false });
  }

}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Header)
);