import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DragDropContext } from "react-beautiful-dnd";
import DraggableElement from "./DraggableElement";
import { fireConfetti } from '../../../utils/CanvasConfetti/CanvasConfetti'
import DragDropSubGameFeedback from './DragDropSubGameFeedback/DragDropSubGameFeedback'
import DragDropSubGameBadge from './DragDropSubGameBadge/DragDropSubGameBadge'

const removeFromList = (list, index) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list, index, element) => {
  const result = Array.from(list);
  result.splice(index, 0, element);
  return result;
};

const playSuccessAudio = () => {
  let successAudio = new Audio('/audios/background/Extras/Success.mp3');
  successAudio.play();
}

const playErrorAudio = () => {
  let errorAudio = new Audio('/audios/background/Extras/Error.mp3');
  errorAudio.play();
}

const listsEsp = [
  { id: "contaminacion", name: "Contaminación" },
  { id: "pesca", name: "Pesca Ilegal" },
  { id: "especies", name: "Especies Invasoras" },
  { id: "clima", name: "Cambio Climático" }
];

const listsEng = [
  { id: "pollution", name: "Pollution" },
  { id: "fishing", name: "Illegal fishing" },
  { id: "species", name: "Invasive species" },
  { id: "climate", name: "Climate Change" }
];

function DragList(props) {

  let showBadge = false;

  let threatType;
  if(props.language === 1){
    threatType = [{ "name": "contaminacion", "state": false }, { "name": "especies", "state": false }, { "name": "pesca", "state": false }, { "name": "clima", "state": false }];
  }else{
    threatType = [{ "name": "pollution", "state": false }, { "name": "species", "state": false }, { "name": "fishing", "state": false }, { "name": "climate", "state": false }];
  }

  const [threats, setThreat] = React.useState(threatType);
  const [feedback, setFeedback] = React.useState(false);
  const [badge, setBadge] = React.useState(showBadge);
  const [elementIdFeedback, setElementIdFeedback] = React.useState(0);

  const displayFeedback = () => {
    setFeedback(true);
  }

  const hideFeedback = () => {
    setFeedback(false);
  }

  const displayBadge = () => {
    setBadge(true);
  }

  const hideBadge = () => {
    setBadge(false);
  }

  const changeThreat = (threat, state) => {

    let currentThreats = threats;
    switch (threat) {
      case "contaminacion":
        currentThreats[0].state = state;
        break;
      case "especies":
        currentThreats[1].state = state;
        break;
      case "pesca":
        currentThreats[2].state = state;
        break;
      case "clima":
        currentThreats[3].state = state;
        break;
      case "pollution":
        currentThreats[0].state = state;
        break;
      case "species":
        currentThreats[1].state = state;
        break;
      case "fishing":
        currentThreats[2].state = state;
        break;
      case "climate":
        currentThreats[3].state = state;
        break;
      default:
        break;
    }
    setThreat(currentThreats);
  };

  let originalElementsEsp = {
    "contaminacion": [
      {
        "id": "clima",
        "prefix": "clima",
        "content": "/images/content/4.4.webp",
        "feedback": "Los bosques dependen de las condiciones de lluvia y humedad para su desarrollo. Su alteración llevaría a la desaparición de las especies que conforman estos ecosistemas, lo cual significaría el fin de este punto verde (Isla del Coco) en medio del océano azul. El cambio climático es un impacto significativo en los patrones del clima a nivel local o global. Este proceso se da por causas naturales y por la actividad humana, como la emisión de dióxido de carbono (CO₂) a la atmósfera que contribuye al calentamiento global. El aumento en la temperatura afecta negativamente a los ecosistemas terrestres y marinos de la Isla del Coco."
      },
      {
        "id": "especies",
        "prefix": "especies",
        "content": "/images/content/8.5.webp",
        "feedback": "Los gatos y las ratas son los depredadores que más daño causan sobre las poblaciones de aves. Esto representa un peligro para las especies endémicas e la Isla del Coco, como el pinzón, el cuclillo y el mosquerito. Además, los gatos también depredan a los geckos. Los mamíferos introducidos desde el siglo XVI son los principales agentes de erosión y modificadores de la flora y fauna local. Los cerdos al alimentarse de raíces erosionan el suelo, lo cual produce suficiente sedimento para afectar ríos, quebradas y arrecifes coralinos. En el caso del venado, este se alimenta de los brotes de plantas, por lo que afecta la regeneración natural de los bosques."
      }
    ],
    "pesca": [

    ],
    "especies": [
      {
        "id": "pesca",
        "prefix": "pesca",
        "content": "/images/content/8.10.webp",
        "feedback": "Debido a la excepcional riqueza marina de la Isla del Coco, esta es muy frecuentada por pescadores. Por ejemplo, entre el 2012 y el 2014, se decomisaron 503,5 km de líneas de pesca ilegal con 13 000 anzuelos en sus aguas protegidas. Las especies que más sufren por la pesca ilegal son los atunes, tiburones, mantarrayas y tortugas. Esto pone en peligro las complejas redes ecológicas de la Isla."
      }
    ],
    "clima": [
      {
        "id": "contaminacion",
        "prefix": "contaminacion",
        "content": "/images/content/8.1.webp",
        "feedback": "Los desechos, producto de la actividad humana, abundan en todos los océanos; la Isla del Coco no está exenta de este problema. Por ejemplo, los plásticos son llevados hasta la Isla por medio de corrientes marinas, desde el continente. Así, se acumulan en sus ambientes y forman botaderos, donde son ingeridos por los organismos que ahí habitan. Esto significa la muerte de cientos de especies por distintas causas, y la afectación de la salud humana al ingerir especies marinas con altas cantidades de plástico."
      }
    ]
  };

  let originalElementsEng = {
    "pollution": [
      {
        "id": "climate",
        "prefix": "Climate Change",
        "content": "/images/content/4.4.webp",
        "feedback": "Forests depend on rain and humidity conditions for their development. Any alteration can lead to the disappearance of species integrating these ecosystems, which would mean the end of this green dot (Cocos Island) in the middle of the blue ocean. Climate change has a significant impact on weather patterns, both locally and globally. This process can be due to natural causes and to human activity, such as the emission of carbon dioxide (CO₂) into the atmosphere, which contributes to global warming. The increase in temperature negatively affects Cocos Island’s terrestrial and marine ecosystems."
      },
      {
        "id": "species",
        "prefix": "Invasive species",
        "content": "/images/content/8.5.webp",
        "feedback": "Cats and rats are the most harmful predators of bird populations. This endangers Cocos Island’s endemic species, such as the Cocos finch, the Cocos cuckoo, and the Cocos flycatcher. Cats also prey on endemic geckos. The mammals introduced in the 16th century are the main agents of erosion and modifiers of local flora and fauna. Pigs feed on roots and erode the soil, which produces enough sediment to affect rivers, streams, and coral reefs. Introduced deer feed on plant shoots, thus altering the natural regeneration of forests."
      }
    ],
    "fishing": [

    ],
    "species": [
      {
        "id": "fishing",
        "prefix": "Illegal fishing",
        "content": "/images/content/8.10.webp",
        "feedback": "Due to Cocos Island’s exceptionally abundant marine life, fishermen often frequent its seaways. For example, between 2012 and 2014, 503.5 km of illegal fishing lines with 13,000 hooks were seized in its protected waters.  The species that suffer most from illegal fishing are tuna, sharks, manta rays, and turtles. This endangers the Island’s complex ecological networks."
      }
    ],
    "climate": [
      {
        "id": "pollution",
        "prefix": "Pollution",
        "content": "/images/content/8.1.webp",
        "feedback": "Waste resulting from human activity abounds in all the oceans. Cocos Island is no exception to this problem. For example, plastics reach the Island from the mainland through ocean currents. They then accumulate in the marine environment and even form “dumps.” Different organisms living in the ocean end up feeding on this waste, which leads to hundreds of species dying from this and other causes. Human health is also affected, as we eat marine species that have ingested considerable amounts of plastic."
      }
    ]
  };


  let correctElementsEsp =
  {
    "contaminacion": [
      {
        "id": "contaminacion",
        "prefix": "contaminacion",
        "content": "/images/content/8.1.webp",
        "feedback": "Los desechos, producto de la actividad humana, abundan en todos los océanos; la Isla del Coco no está exenta de este problema. Por ejemplo, los plásticos son llevados hasta la Isla por medio de corrientes marinas, desde el continente. Así, se acumulan en sus ambientes y forman botaderos, donde son ingeridos por los organismos que ahí habitan. Esto significa la muerte de cientos de especies por distintas causas, y la afectación de la salud humana al ingerir especies marinas con altas cantidades de plástico."
      }
    ],
    "pesca": [
      {
        "id": "pesca",
        "prefix": "pesca",
        "content": "/images/content/8.10.webp",
        "feedback": "Debido a la excepcional riqueza marina de la Isla del Coco, esta es muy frecuentada por pescadores. Por ejemplo, entre el 2012 y el 2014, se decomisaron 503,5 km de líneas de pesca ilegal con 13 000 anzuelos en sus aguas protegidas. Las especies que más sufren por la pesca ilegal son los atunes, tiburones, mantarrayas y tortugas. Esto pone en peligro las complejas redes ecológicas de la Isla."
      }
    ],
    "especies": [
      {
        "id": "especies",
        "prefix": "especies",
        "content": "/images/content/8.5.webp",
        "feedback": "Los gatos y las ratas son los depredadores que más daño causan sobre las poblaciones de aves. Esto representa un peligro para las especies endémicas e la Isla del Coco, como el pinzón, el cuclillo y el mosquerito. Además, los gatos también depredan a los geckos. Los mamíferos introducidos desde el siglo XVI son los principales agentes de erosión y modificadores de la flora y fauna local. Los cerdos al alimentarse de raíces erosionan el suelo, lo cual produce suficiente sedimento para afectar ríos, quebradas y arrecifes coralinos. En el caso del venado, este se alimenta de los brotes de plantas, por lo que afecta la regeneración natural de los bosques."
      }
    ],
    "clima": [
      {
        "id": "clima",
        "prefix": "clima",
        "content": "/images/content/4.4.webp",
        "feedback": "Los bosques dependen de las condiciones de lluvia y humedad para su desarrollo. Su alteración llevaría a la desaparición de las especies que conforman estos ecosistemas, lo cual significaría el fin de este punto verde (Isla del Coco) en medio del océano azul. El cambio climático es un impacto significativo en los patrones del clima a nivel local o global. Este proceso se da por causas naturales y por la actividad humana, como la emisión de dióxido de carbono (CO₂) a la atmósfera que contribuye al calentamiento global. El aumento en la temperatura afecta negativamente a los ecosistemas terrestres y marinos de la Isla del Coco."
      }
    ]
  };

  let correctElementsEng =
  {
    "pollution": [
      {
        "id": "pollution",
        "prefix": "Pollution",
        "content": "/images/content/8.1.webp",
        "feedback": "Waste resulting from human activity abounds in all the oceans. Cocos Island is no exception to this problem. For example, plastics reach the Island from the mainland through ocean currents. They then accumulate in the marine environment and even form “dumps.” Different organisms living in the ocean end up feeding on this waste, which leads to hundreds of species dying from this and other causes. Human health is also affected, as we eat marine species that have ingested considerable amounts of plastic."
      }
    ],
    "fishing": [
      {
        "id": "fishing",
        "prefix": "Illegal fishing",
        "content": "/images/content/8.10.webp",
        "feedback": "Due to Cocos Island’s exceptionally abundant marine life, fishermen often frequent its seaways. For example, between 2012 and 2014, 503.5 km of illegal fishing lines with 13,000 hooks were seized in its protected waters.  The species that suffer most from illegal fishing are tuna, sharks, manta rays, and turtles. This endangers the Island’s complex ecological networks."
      }
    ],
    "species": [
      {
        "id": "species",
        "prefix": "Invasive species",
        "content": "/images/content/8.5.webp",
        "feedback": "Cats and rats are the most harmful predators of bird populations. This endangers Cocos Island’s endemic species, such as the Cocos finch, the Cocos cuckoo, and the Cocos flycatcher. Cats also prey on endemic geckos. The mammals introduced in the 16th century are the main agents of erosion and modifiers of local flora and fauna. Pigs feed on roots and erode the soil, which produces enough sediment to affect rivers, streams, and coral reefs. Introduced deer feed on plant shoots, thus altering the natural regeneration of forests."
      }
    ],
    "climate": [
      {
        "id": "climate",
        "prefix": "Climate Change",
        "content": "/images/content/4.4.webp",
        "feedback": "Forests depend on rain and humidity conditions for their development. Any alteration can lead to the disappearance of species integrating these ecosystems, which would mean the end of this green dot (Cocos Island) in the middle of the blue ocean. Climate change has a significant impact on weather patterns, both locally and globally. This process can be due to natural causes and to human activity, such as the emission of carbon dioxide (CO₂) into the atmosphere, which contributes to global warming. The increase in temperature negatively affects Cocos Island’s terrestrial and marine ecosystems."
      },
    ]
  };

  let correctElements;
  let originalElements;
  if(props.language === 1){
    originalElements = originalElementsEsp;
    correctElements = correctElementsEsp;
  }else{
    originalElements = originalElementsEng;
    correctElements = correctElementsEng;
  }

  const [elements, setElements] = React.useState(originalElements);


  const onDragEnd = (result) => {

    if (!result.destination) {
      return;
    }

    const listCopy = { ...elements };

    const sourceList = listCopy[result.source.droppableId];
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result.source.index
    );
    listCopy[result.source.droppableId] = newSourceList;
    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(
      destinationList,
      result.destination.index,
      removedElement
    );

    setElements(listCopy);

    if (result.draggableId === result.destination.droppableId) {
      fireConfetti();
      playSuccessAudio();
      setElementIdFeedback(listCopy[result.draggableId][0].feedback);
      displayFeedback();
      changeThreat(result.draggableId, true);

      //Checkig if show badge.
      if (threats[0].state === true && threats[1].state === true && threats[2].state === true && threats[3].state === true) {

        //Change state internaly;
        let currentBadges = props.state.badges;
        currentBadges[4].obtained = true;
        props.badgeObtained(currentBadges);

        displayBadge();
      }

    } else {
      playErrorAudio();
      changeThreat(result.draggableId, false);
    }

  };

  let lists;
  if(props.language === 1){
    lists = listsEsp;
  }else{
    lists = listsEng;
  }

  return (

    <div className="dragdrop-container">

      {
        (feedback === true) ? <DragDropSubGameFeedback language={props.language} hideFeedback={hideFeedback} feedback={elementIdFeedback} /> : ''
      }

      {
        (badge === true && feedback === false) ? <DragDropSubGameBadge language={props.language} hideBadge={hideBadge} /> : ''
      }

      <DragDropContext onDragEnd={onDragEnd}>

        <div className="lists-container">

          {
            lists.map((list) => (
              <DraggableElement
                language={props.language}
                elements={props.state.badges[4].obtained === true ? correctElements[list.id] : elements[list.id]}
                key={list.id}
                prefix={list.id}
                name={list.name}
              />
            ))}

        </div>

      </DragDropContext>

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    badgeObtained: (badges) => {
      dispatch({ type: 'BADGE_OBTAINED', badges });
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DragList));

