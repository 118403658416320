import React, { Component } from 'react';
import './SubModule.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import Header from '../../utils/Header/Header';
import Footer from '../../utils/Footer/Footer';
import Toolbar from '../../utils/Toolbar/Toolbar';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import PageEsp from '../../content/page-esp.json';
import PageEng from '../../content/page-eng.json';
import PuzzleInstructions from '../../utils/PuzzleInstructions/PuzzleInstructions';
import MemoryInstructions from '../../utils/MemoryInstructions/MemoryInstructions';
import MatchInstructions from '../../utils/MatchInstructions/MatchInstructions';
import QuestionInstructions from '../../utils/QuestionInstructions/QuestionInstructions';
import { parse } from 'dom-parser-react'
import UtilEsp from '../../content/util-esp.json';
import UtilEng from '../../content/util-eng.json';

class Page extends Component {

  constructor(props) {
    super();
    this.state = {
      game: false,
      showDescriptions: true,
      currentSubModule: 1,
      item: {
        "module": "",
        "moduleName": "",
        "submodule": 1,
        "submoduleName": "",
        "background": "",
        "backgroundAudio": "",
        "backgroundAudioSrc": "",
        "textAudioSrc": "",
        "information": [],
        "images": [],
        "videos": [],
        "games": []
      },
      props: props
    };
    this.videoRef1 = React.createRef();
    this.videoRef2 = React.createRef();
    this.videoRef3 = React.createRef();
    this.videoRef4 = React.createRef();
    this.changeItem = this.changeItem.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.openGame = this.openGame.bind(this);
    this.startGame = this.startGame.bind(this);
    this.closeGame = this.closeGame.bind(this);
    this.playVideo = this.playVideo.bind(this);
    this.toggleDescriptions = this.toggleDescriptions.bind(this);
    this.renderCustomControls = this.renderCustomControls.bind(this);
  };

  componentDidMount() {
    this.setState({ currentSubModule: parseInt(this.props.match.params.submodule) });
    this.changeItem(this.props.match.params.submodule);
    this.props.changeCurrentSubmodule(this.props.match.params.submodule);
  }

  changeItem(item) {
    let page;
    if (this.props.state.language === 1) {
      page = PageEsp;
    } else {
      page = PageEng;
    }
    let filteredItem = page.filter(submodule => submodule.submodule === parseInt(item));
    if (filteredItem.length > 0) {
      this.setState({ item: filteredItem[0] });
      this.state.props.history.push('/submodule/' + filteredItem[0].submodule);
      this.props.changeCurrentSubmodule(filteredItem[0].submodule);
      this.setState({ currentSubModule: filteredItem[0].submodule });
    } else {
      this.state.props.history.push('/');
    }
  }

  previous() {
    let previous = this.state.item.previous;
    if (previous !== -1) {
      this.props.changeCurrentSubmodule(previous);
      this.changeItem(previous);
      this.state.props.history.push('/submodule/' + previous);
    } else {
      this.state.props.history.push('/');
    }
  }

  next() {
    let next = this.state.item.next;
    if (next !== -1) {
      this.props.changeCurrentSubmodule(next);
      this.changeItem(next);
      this.state.props.history.push('/submodule/' + next);
    } else {
      this.state.props.history.push('/');
    }
  }

  openGame(event) {
    if (this.state.item.games.length > 0) {
      event.preventDefault();
      this.setState({ game: true });
    } else {
      event.preventDefault();
    }
  }

  closeGame() {
    this.setState({ game: false });
  }

  playVideo(index) {
    let ref = '';
    if (index === 0) {
      ref = this.videoRef1;
    } else if (index === 1){
      ref = this.videoRef2;
    }else if(index === 2){
      ref = this.videoRef3;
    }else{
      ref = this.videoRef4;
    }
    ref.current.load();
  }


  startGame() {
    if (this.state.item.games[0].type === 'Puzzle') {
      this.props.history.push('/submodule/' + this.state.currentSubModule + '/puzzle-game');
    } else if (this.state.item.games[0].type === 'Memory') {
      this.props.history.push('/submodule/' + this.state.currentSubModule + '/memory-game');
    } else if (this.state.item.games[0].type === 'Match') {
      this.props.history.push('/submodule/' + this.state.currentSubModule + '/match-game');
    } else if (this.state.item.games[0].type === 'Question') {
      this.props.history.push('/submodule/' + this.state.currentSubModule + '/question-game');
    }
  }

  toggleDescriptions(){
    this.setState({showDescriptions: !this.state.showDescriptions});
  };

  renderCustomControls() {
    return (
      <button
        key="show-hide-button"
        className="image-gallery-custom-button"
        onClick={this.toggleDescriptions}
      >
        {this.state.showDescriptions ? (this.props.state.language === 1 ? "Ocultar descripción de la imagen" : "Hide image description") : (this.props.state.language === 1 ? "Mostrar descripción de la imagen" : "Show image description")}
      </button>
    );
  }

  render() {

    document.getElementById('Header')?.scrollIntoView();

    let language = this.props.state.language;
    let util;
    if (language === 1) {
      util = UtilEsp;
    } else {
      util = UtilEng;
    }

    let activity = '';
    if (this.state.item.games.length > 0) {
      activity = <div className="actividades">
        <div className="game-wrap">
          <div className="button-container" onClick={(event) => this.openGame(event)}>
            <img className="conoce" src={language === 1 ? "/images/icon/juegos.png" : "/images/icon/games.png"} alt="juegos interactivos" />
            <p>{util[0].play}</p>
          </div>
        </div>
      </div>
    }

    let game = '';
    if (this.state.game === true) {
      if (this.state.item.games[0].type === 'Puzzle') {
        game = <PuzzleInstructions language={language} closeAction={this.closeGame} startGame={this.startGame} />;
      } else if (this.state.item.games[0].type === 'Memory') {
        game = <MemoryInstructions language={language} closeAction={this.closeGame} startGame={this.startGame} />;
      } else if (this.state.item.games[0].type === 'Match') {
        game = <MatchInstructions language={language} closeAction={this.closeGame} startGame={this.startGame} />;
      } else if (this.state.item.games[0].type === 'Question') {
        game = <QuestionInstructions language={language} closeAction={this.closeGame} startGame={this.startGame} />;
      }
    }

    let images = [];
    this.state.item.images.map((image, index) => {
      images.push(
        {
          thumbnail: image.src,
          original: image.src,
          description: this.state.showDescriptions ? parse(image.description, { createElement: React.createElement, Fragment: React.Fragment, }) : undefined
        }
      );
      return true;
    })

    let existsVideo = this.state.item.videos.length > 0;
    let video = [];
    if (existsVideo) {
      video = this.state.item.videos;
    }

    let submodule = parse(this.state.item.submoduleName, {
      createElement: React.createElement,
      Fragment: React.Fragment,
    });

    return (

      <div className="page__container" style={{ backgroundImage: this.state.item.background }}>

        <Header submodule="true" changeItem={this.changeItem} />

        {game}

        <div className="background-audio">
          <div className="audio-text__container">
            <p>{this.state.item.backgroundAudio}</p>
          </div>
          <AudioPlayer
            id="player2"
            ref={(element) => { this.ambient = element; }}
            autoPlayAfterSrcChange
            autoPlay
            loop
            volume="0.1"
            showControls={false}
            showDownloadProgress={false}
            showJumpControls={false}
            customAdditionalControls={[]}
            src={this.state.item.backgroundAudioSrc}
          />
        </div>

        <div className="module-title">
          <h1 className="module-title__title">{this.state.item.moduleName}</h1>
          <h1 className="module-title__description">{submodule}</h1>
        </div>

        <div className="content">

          <div className="left-content">

            <div className="text-audio">
              <div className="audio-text__container">
                <p>{util[0].narration}</p>
              </div>
              <AudioPlayer
                id="player1"
                autoPlayAfterSrcChange={false}
                autoPlay={false}
                customAdditionalControls={[]}
                src={this.state.item.textAudioSrc}
              />
            </div>

            {
              this.state.item.information.map((information, index) => {
                return (
                  <div key={index}>
                    <h2>{parse(information.title, { createElement: React.createElement, Fragment: React.Fragment, })}</h2>
                    <p>{parse(information.description, { createElement: React.createElement, Fragment: React.Fragment, })}</p>
                  </div>
                )
              })
            }

          </div>

          <div className="right-content">

            <React.Fragment>
              <ImageGallery
                items={images}
                thumbnailPosition="bottom"
                renderCustomControls={this.renderCustomControls}
                />
            </React.Fragment>

            <div className="videos">
              {
                video.map((videoContent, index) => {
                  return (
                    <div key={'video' + index}>

                      <video ref={(index === 0) ? this.videoRef1 : (index === 1) ? this.videoRef2 : (index === 2) ? this.videoRef3 : this.videoRef4} key={submodule + 'video' + index} className='video' onEnded={() => this.playVideo(index)} controls controlsList="nodownload">
                        <source src={videoContent.src} type='video/mp4' />
                      </video>

                      <h2>{parse(videoContent.title, { createElement: React.createElement, Fragment: React.Fragment, })}</h2>
                      <p className="videos__description">{parse(videoContent.description, { createElement: React.createElement, Fragment: React.Fragment, })}</p>
                    </div>
                  )
                })
              }
            </div>


            {activity}

          </div>
        </div>
        <Footer />
        <div className="empty-space"></div>
        <Toolbar submodule={this.state.item.submoduleName} language={this.props.state.language} previous={this.previous} next={this.next} />
      </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCurrentSubmodule: (currentSubModule) => {
      dispatch({ type: 'CHANGE_CURRENT_SUBMODULE', currentSubModule });
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
