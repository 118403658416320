import React, { Component } from 'react';
import './Visits.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Footer from '../../utils/Footer/Footer';
import Header from '../../utils/Header/Header';
import VisitsEsp from '../../content/visits-esp.json';
import VisitsEng from '../../content/visits-eng.json';
import AudioPlayer from 'react-h5-audio-player';
import UtilEsp from '../../content/util-esp.json';
import UtilEng from '../../content/util-eng.json';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

class Visits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphType: "year", // Default graph type
      yearSelected: "", // Default selected year (will be set in componentDidMount)
      monthlyVisits: [], // Store monthly visits data
      monthlyVisitsYear: [], // Store monthly visits data for selected year
      yearlyVisits: [], // Store yearly visits data
      countryVisits: [], // Store country visits data
      allYearMonthsVisits: [], // Store all year-months visits data
      options: {
        scales: {
          y: {
            ticks: {
              color: 'white', beginAtZero: true, font: {
                size: 15
              }
            },
            grid: {
              display: true,
              color: "#00000063"
            },
          },
          x: {
            ticks: {
              color: 'white', beginAtZero: true, font: {
                size: 15
              }
            },
            grid: {
              display: true,
              color: "#00000063"
            },
          }
        },
        indexAxis: "y",
        elements: {
          bar: {
            borderWidth: 2
          }
        },
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              color: "white",
              padding: 40,
              font: {
                size: 15
              }
            }
          },
          title: {
            display: false,
            text: ""
          }
        }
      }
    };
  }

  componentDidMount() {
    this.fetchYearlyVisits();
    this.fetchMonthlyVisits();
    this.fetchAllYearMonthsVisits();
  }

  // Function to fetch monthly visits for all years
  fetchMonthlyVisits = () => {
    fetch('https://travesiaisladelcoco.museo.ucr.ac.cr/backend/visits/monthly')
      .then(response => response.json())
      .then(data => {
        this.setState({ monthlyVisits: data });
      })
      .catch(error => console.error('Error fetching monthly visits:', error));
  }

  // Function to fetch monthly visits for a specific year
  fetchMonthlyVisitsSpecificYear = () => {
    const { yearSelected } = this.state;
    fetch(`https://travesiaisladelcoco.museo.ucr.ac.cr/backend/visits/${yearSelected}`)
      .then(response => response.json())
      .then(data => {
        this.setState({ monthlyVisitsYear: data });
      })
      .catch(error => console.error('Error fetching monthly visits for specific year:', error));
  }

  // Function to fetch yearly visits
  fetchYearlyVisits = () => {
    fetch('https://travesiaisladelcoco.museo.ucr.ac.cr/backend/visits/years')
      .then(response => response.json())
      .then(data => {
        // Set state with yearly visits data
        this.setState({ yearlyVisits: data }, () => {
          // After setting state, set the default yearSelected to the latest year
          const latestYear = this.getLatestYear();
          this.setState({ yearSelected: latestYear }, () => {
            // After setting yearSelected, fetch monthly visits for the selected year
            this.fetchMonthlyVisitsSpecificYear();
          });
        });
      })
      .catch(error => console.error('Error fetching yearly visits:', error));
  }

  // Function to fetch country visits
  fetchCountryVisits = () => {
    fetch('https://travesiaisladelcoco.museo.ucr.ac.cr/backend/visits/countries')
      .then(response => response.json())
      .then(data => {
        this.setState({ countryVisits: data });
      })
      .catch(error => console.error('Error fetching country visits:', error));
  }

  // Function to fetch all year-months visits
  fetchAllYearMonthsVisits = () => {
    fetch('https://travesiaisladelcoco.museo.ucr.ac.cr/backend/visits/monthly')
      .then(response => response.json())
      .then(data => {
        this.setState({ allYearMonthsVisits: data });
      })
      .catch(error => console.error('Error fetching all year-months visits:', error));
  }

  // Function to get the latest year from yearlyVisits
  getLatestYear = () => {
    const { yearlyVisits } = this.state;
    if (yearlyVisits.length > 0) {
      // Sort yearlyVisits by year in descending order and return the first element (latest year)
      const sortedYears = yearlyVisits.sort((a, b) => b.year - a.year);
      return sortedYears[0].year.toString(); // Convert year to string for consistency
    }
    return ""; // Default to empty string if yearlyVisits is empty
  }

  // Function to handle change in first filter (graph type)
  changeFirstFilter = (event) => {
    const graphType = event.target.value;
    this.setState({ graphType });
  }

  // Function to handle change in second filter (year selection)
  changeYearFilter = (event) => {
    const yearSelected = event.target.value;
    this.setState({ yearSelected }, () => {
      this.fetchMonthlyVisitsSpecificYear(); // Fetch monthly visits for selected year
    });
  }

  // Function to render data based on graph type
  renderGraphData = () => {
    const { graphType, yearlyVisits, monthlyVisitsYear, allYearMonthsVisits } = this.state;

    switch (graphType) {
      case "year":
        const yearlyLabels = yearlyVisits.map(entry => entry.year);
        const yearlyData = {
          labels: yearlyLabels,
          datasets: [
            {
              label: 'Total',
              data: yearlyVisits.map(entry => entry.total_visits),
              borderColor: "#ff8000",
              backgroundColor: "#ff8000"
            }
          ]
        };
        return <Bar options={this.state.options} data={yearlyData} />;

      case "year_months":
        const monthlyLabels = monthlyVisitsYear.map(entry => entry.month);
        const monthlyData = {
          labels: monthlyLabels,
          datasets: [
            {
              label: 'Total',
              data: monthlyVisitsYear.map(entry => entry.total_visits),
              borderColor: "#ff8000",
              backgroundColor: "#ff8000"
            }
          ]
        };
        return <Bar options={this.state.options} data={monthlyData} />;

      case "all_year_months":
        const allYearMonthsLabels = allYearMonthsVisits.map(entry => entry.month);
        const allYearMonthsData = {
          labels: allYearMonthsLabels,
          datasets: [
            {
              label: 'Total',
              data: allYearMonthsVisits.map(entry => entry.total_visits),
              borderColor: "#ff8000",
              backgroundColor: "#ff8000"
            }
          ]
        };
        return <Bar options={this.state.options} data={allYearMonthsData} />;

      default:
        return null;
    }
  }

  render() {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );

    let visitsText = this.props.state.language === 1 ? VisitsEsp : VisitsEng;
    let util = this.props.state.language === 1 ? UtilEsp : UtilEng;

    return (
      <div className="module__container">
        <div className="page__content">
          <Header submodule="true" />
          <div className="visits-content" style={{ backgroundImage: 'url(/images/backgrounds/Origen.webp)' }}>
            <div className="game-audio-container">
              <div className="background-audio">
                <div className="audio-text__container">
                  <p>{util[0].backgroundAudio}</p>
                </div>
                <AudioPlayer
                  autoPlay
                  loop
                  volume="1"
                  showControls={false}
                  showDownloadProgress={false}
                  showJumpControls={false}
                  customAdditionalControls={[]}
                  src='/audios/background/Extras/2.mp3'
                />
              </div>
            </div>

            <div className="visits-center_top">
              <h1 className="visits-center__title">{visitsText[0].title}</h1>
            </div>

            <div className="visits">
              <div className="visits-filters">
                <div className="visits-filters-filter">
                  <label htmlFor="type">{visitsText[0].graphType}</label>
                  <select name="type" id="type" onChange={this.changeFirstFilter} value={this.state.graphType}>
                    <option value="year">{visitsText[0].year}</option>
                    <option value="year_months">{visitsText[0].year_months}</option>
                    <option value="all_year_months">{visitsText[0].all_year_months}</option>
                  </select>
                </div>

                {this.state.graphType === "year_months" && (
                  <div className="visits-filters-filter">
                    <label htmlFor="year">{visitsText[0].yearLegend}</label>
                    <select name="year" id="year" onChange={this.changeYearFilter} value={this.state.yearSelected}>
                      {this.state.yearlyVisits.map(entry => (
                        <option key={entry.year} value={entry.year}>{entry.year}</option>
                      ))}
                    </select>
                  </div>
                )}
              </div>

              <div className="visits-graph">
                {this.renderGraphData()}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state
});

export default withRouter(connect(mapStateToProps)(Visits));
