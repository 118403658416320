import React from "react";
import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import './DragDropSubGame.css'

const DraggableElement = ({ language, prefix, elements, name, styleType }) => (

  <div className={"list-column" + (styleType === 'row' ? '-row' : '')}>

    <div className="column-header">{name}</div>

    <Droppable droppableId={`${prefix}`} >

      {(provided) => (

        <div className={"drop-space" + (styleType === 'row' ? '-row' : '-column') }
          ref={provided.innerRef}
          {...provided.droppableProps}>

          <div {...provided.droppableProps} ref={provided.innerRef}>

            {elements.map((item, index) => (
              <ListItem key={item.id} item={item} index={index} />
            ))}

            {provided.placeholder}

            <p className="dropspace-placeholder">{language === 1 ? 'Arrastre elementos aquí.' : 'Draw and drop elements here.'}</p>

          </div>

        </div>
      )}

    </Droppable>

  </div>
);

export default DraggableElement;
