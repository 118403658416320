import React from 'react';
import './GeneralGameInstructions.css';
import '../PopUp/PopUp.css';
import { withRouter } from 'react-router-dom';
import instrucctionsEsp from '../../content/general-game-instructions-esp.json';
import instrucctionsEng from '../../content/general-game-instructions-eng.json';

function GeneralGameInstructions(props) {

  let language = props.language;
  let instrucctions;
  if (language === 1){
    instrucctions = instrucctionsEsp;
  } else{
    instrucctions = instrucctionsEng;
  }

  return (
    <div className="pop-up-container">
      <div className="pop-up">
        <div className="pop-up__header">
          <img
            className="pop-up__close"
            src="/images/icon/close.png"
            onClick={() => props.closeAction()}
            alt="Cerrar dialogo."
          />
        </div>
        <div className="pop-up__info-block">

          <div className="center-text">
            <h2 className="game-title">Trivia</h2>
            <h1>{instrucctions[0].subtitle}</h1>
          </div>

          <div className="left-text">
            <p>{instrucctions[0].instrucctions}</p>
            <ul>
              <li key="1">{instrucctions[0].first}</li>
              <li key="2">{instrucctions[0].second}</li>
              <li key="3">{instrucctions[0].third}</li>
              <li key="4">{instrucctions[0].fourth}</li>
              <li key="5">{instrucctions[0].fifth}</li>
            </ul>
          </div>

          <div className="center-panel">
            <button onClick={() => props.startGame()}>{instrucctions[0].start}</button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default withRouter(GeneralGameInstructions);
