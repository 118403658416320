import React, { Component } from 'react';
import './Home.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Footer from '../../utils/Footer/Footer';
import Header from '../../utils/Header/Header';
import "react-image-gallery/styles/scss/image-gallery.scss";
import IntroductionEsp from '../../content/Home-introduction-esp.json';
import IntroductionEng from '../../content/Home-introduction-eng.json';
import ModuleEsp from '../../content/Home_Menu-modules-esp.json';
import ModuleEng from '../../content/Home_Menu-modules-eng.json';
import Game from '../../utils/GeneralGameInstructions/GeneralGameInstructions';
import VisitInformation from '../../utils/VisitInformation/VisitInformation';
import AudioPlayer from 'react-h5-audio-player';
import { parse } from 'dom-parser-react'

class Home extends Component {
  constructor(props) {
    super();
    this.state = {
      game: false,
      props: props
    };
    this.openGame = this.openGame.bind(this);
    this.closeGame = this.closeGame.bind(this);
    this.startGame = this.startGame.bind(this);
    this.setVisitInfo = this.setVisitInfo.bind(this);
  }

  openSubModulePages(submodule) {
    this.props.history.push('/submodule/' + submodule);
  }

  openGame(event) {
    event.preventDefault();
    this.setState({ game: true });
  }

  closeGame() {
    this.setState({ game: false });
  }

  startGame() {
    this.props.history.push('/generalGame');
  }

  setVisitInfo(country) {
    this.props.changeVisitInfoObteined();
     // Prepare the data to send in the POST request
    const data = { country: country };
    // Make a POST request to the /visits endpoint
    fetch('https://travesiaisladelcoco.museo.ucr.ac.cr/backend/visits', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Assuming response is JSON
    })
    .then(data => {
      console.log('Visit saved successfully:', data.message);
    })
    .catch(error => {
      console.error('Error saving visit:', error);
      // Handle error appropriately
    });
  }
  
  render() {

    let game = '';
    if (this.state.game) {
      game = <Game language={this.props.state.language} closeAction={this.closeGame} startGame={this.startGame} />;
    }

    let visitInfoObteined = '';
    if (!this.props.state.visitInfoObteined) {
      visitInfoObteined = <VisitInformation language={this.props.state.language} setVisitInfo={this.setVisitInfo} changeLanguage={this.props.changeLanguage} />;
    }

    let moduleText = "";
    let introductionText = "";
    let introductionFirst = "";
    let introductionSecond = "";
    let backgroundAudio = "";
    let startGame = "";

    if (this.props.state.language === 1) {
      introductionText = IntroductionEsp;
      moduleText = ModuleEsp;
    } else {
      introductionText = IntroductionEng;
      moduleText = ModuleEng;
    }

    introductionText.map(record => {

      introductionFirst = parse(record.first, {
        createElement: React.createElement,
        Fragment: React.Fragment,
      });
      introductionSecond = parse(record.second, {
        createElement: React.createElement,
        Fragment: React.Fragment,
      });
      backgroundAudio = parse(record.backgroundAudio, {
        createElement: React.createElement,
        Fragment: React.Fragment,
      });
      startGame = parse(record.startGame, {
        createElement: React.createElement,
        Fragment: React.Fragment,
      });
      return true;
    })

    return (
      <div className="module__container" >

        <div className="page__content">

          <Header />

          {visitInfoObteined}

          {game}

          <div className="game-audio-container-home">
            <div className="background-audio">
              <div className="audio-text__container">
                <p>{backgroundAudio}</p>
              </div>
              <AudioPlayer
                autoPlay
                loop
                volume="1"
                showControls={false}
                showDownloadProgress={false}
                showJumpControls={false}
                customAdditionalControls={[]}
                src='/audios/background/Extras/2.mp3'
              />
            </div>
          </div>

          <div className="introduction">

            <div className="introduction__main-content">
              <div className="introduction__container">
                <p>
                  {introductionFirst}
                </p>
                <p>
                  {introductionSecond}
                </p>

                <div className="wrap" onClick={(event) => this.openGame(event)} >
                  <div className="button-container">
                    <img className="conoce" src={'/images/icon/conoce' + (this.props.state.language === 1 ? '-esp' : '-eng') + '.png'} alt="trivia ¿conoce de la isla?" />
                    <p>{startGame}</p>
                  </div>
                </div>

              </div>
              <div className="icons">
                <img className="icon" src={"/images/logo/logo" + (this.props.state.language === 1 ? '-esp' : '-eng') + '.png'} alt="logo travesia por la isla del coco" />
              </div>
            </div>
          </div>


          {
            moduleText.map((module, index) => {
              return (
                <div key={module + '-' + index} className="module-separator" id={module.id} style={{ backgroundImage: module.background, backgroundPositionX: "center" }}>

                  <div className="module-title">
                    <h1 className="module-title__title">{parse(module.name, {
                      createElement: React.createElement,
                      Fragment: React.Fragment,
                    })}</h1>
                    <h1 className="module-title__description">{parse(module.slogan, {
                      createElement: React.createElement,
                      Fragment: React.Fragment,
                    })}</h1>
                  </div>

                  <div className="card__wrap--outer">

                    <div className="card__wrap--inner">

                      {
                        module.submodules.map((submodule, index) => {
                          return (

                            <div key={module + '-' + submodule + '-' + index} className="card" onClick={() => this.openSubModulePages(submodule.id)}>

                              <img className="submodule__image" src={"/images/content/" + submodule.image} alt="Anterior" />
                              <h2>{parse(submodule.name, {
                                createElement: React.createElement,
                                Fragment: React.Fragment,
                              })}</h2>
                              <p className="flexible">{parse(submodule.description, {
                                createElement: React.createElement,
                                Fragment: React.Fragment,
                              })}</p>
                              <div className="submodule-contents">
                                <img className={submodule.activities.includes("image") ? 'submodule__asset' : 'submodule__asset_none'} src="/images/icon/image.png" alt="Images"/>
                                <img className={submodule.activities.includes("video") ? 'submodule__asset' : 'submodule__asset_none'}  src="/images/icon/video.png" alt="Videos"/>
                                <img className={submodule.activities.includes("game") ? 'submodule__asset' : 'submodule__asset_none'}  src="/images/icon/game.png" alt="Games"/>
                                <p className="submodule__more">{this.props.state.language === 1 ? 'Leer más' : 'Read more'}</p>
                              </div>
                            </div>
                          )
                        })
                      }

                    </div>

                  </div>

                </div>
              )
            })
          }

          <Footer />

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeVisitInfoObteined: (visitInfoObteined) => {
      dispatch({ type: 'CHANGE_VISIT_INFO_OBTEINED', visitInfoObteined});
    },
    changeLanguage: (language) => {
      dispatch({ type: 'CHANGE_LANGUAGE', language });
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
