import React, { Component } from 'react';
import './References.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Footer from '../../utils/Footer/Footer';
import Header from '../../utils/Header/Header';
import ReferencesEsp from '../../content/references-esp.json';
import ReferencesEng from '../../content/references-eng.json';
import AudioPlayer from 'react-h5-audio-player';
import UtilEsp from '../../content/util-esp.json';
import UtilEng from '../../content/util-eng.json';

class References extends Component {
  constructor(props) {
    super();
    this.state = {
      props: props
    };
  }

  render() {

    let referencesText = '';
    let util = '';
    if (this.props.state.language === 1) {
      referencesText = ReferencesEsp;
      util = UtilEsp;
    } else {
      referencesText = ReferencesEng;
      util = UtilEng;
    }

    return (
      <div className="module__container" >

        <div className="page__content">

          <Header submodule="true" />

          <div className="references-content">

            <div className="game-audio-container">
              <div className="background-audio">
                <div className="audio-text__container">
                  <p>{util[0].backgroundAudio}</p>
                </div>
                <AudioPlayer
                  autoPlay
                  loop
                  volume="1"
                  showControls={false}
                  showDownloadProgress={false}
                  showJumpControls={false}
                  customAdditionalControls={[]}
                  src='/audios/background/Extras/3.mp3'
                />
              </div>
            </div>

            <div className="references-center_top">
              <h1 className="references-center__title">{referencesText[0].title}</h1>
            </div>

            <div className="references">

              <h3>{referencesText[0].compiler}</h3>
              <p>Jorge Cortés Núñez, Centro de Investigación en Ciencias del Mar y Limnología (CIMAR), Universidad de Costa Rica</p>
              <p>{referencesText[0].date} 2023</p>
              <h3>{referencesText[0].note}: {referencesText[0].noteDescription}</h3>


              <h2>{referencesText[0].marine}</h2>
              <ol>
                <li>Acevedo-Gutiérrez, A. 1996. Lista de mamíferos marinos en Golfo Dulce e Isla del Coco. Revista de Biología Tropical, 44: 933-934</li>
                <li>Acuña, F.H., J. Cortés & A. Garese. 2012. Ocurrence of the sea anemone Telmatactis panamensis (Verrill, 1869) (Cnidaria: Anthozoa: Actiniaria) at Isla del Coco National Park, Costa Rica. Revista de Biología Tropical, 60 (Supplement 3): 201-205.</li>
                <li>Acuña, F.H., J. Cortés, A. Garese & R. González-Muñoz. 2020. The sea anemone Exaiptasia diaphana (Actiniaria: Aiptasiidae) associated to rhodoliths at Isla del Coco National Park, Costa Rica. Revista de Biología Tropical, 68 (Supplement 1): S283-S288.</li>
                <li>Alvarado, J. J. (2010). Isla del Coco (Costa Rica) Echinoderms: State of knowledge, In Harris, L. G., Böttger, S. A., Walker, C. W. & Lesser, M. P. (Eds.), Echinoderms: Durham. Proceedings 12th International Echinoderm Conference. (pp. 103-113). Leiden, Netherlands: CRC Press, Taylor & Francis Group, Balkema.</li>
                <li>Alvarado, J.J., J.L. Chacón-Monge, J.C. Azofeifa-Solano & J. Cortés. 2022. Diversity of deep-sea echinoderms from Costa Rica. Frontiers in Marine Science, 9, 918878. DOI: 10.3389/fmars.2022.918878</li>
                <li>Angulo, A., B. Naranjo-Elizondo, M. Corrales-Ugalde, and J. Cortés. 2014. First record of the genus Paracaristius (Perciformes: Caristiidae) from the Pacific of Central America, with comments on their association with the siphonophore Praya reticulata (Siphonophorae: Prayidae). Marine Biodiversity Records, 7: e132. DOI: 10.1017 /S1755267214001262</li>
                <li>Azofeifa-Solano, J.C. & J. Cortés. 2021. Chapter 24: Diversity and biology of deep-water crustaceans in Costa Rica, pp. 633-658. In: M.E. Hendrickx (Ed.), Deep-Sea Pycnogonids and Crustaceans of the Americas. Springer Nature, Switzerland. DOI: 10.1007/978-3-030-58410-8_24</li>
                <li>Azofeifa-Solano, J.C., M. Corrales-Ugalde, I. Castellanos-Osorio & Á. Morales-Ramírez. 2016. Euphausiids (Crustacea: Euphausiacea) from a hotspot of marine biodiversity, Isla del Coco, Costa Rica, Eastern Tropical Pacific. Revista de Biología Tropical, 64 (Supplement 1): S221-S230.</li>
                <li>Azofeifa-Solano, J.C., M. Fourriére & P. Horgan. 2016. First record of an adult Galapagos slipper lobster, Scyllarides astori, (Decapoda, Scyllaridae) from Isla del Coco, Eastern Tropical Pacific. Marine Biodiversity Records, 9: 3-5.</li>
                <li>Biolley, P. 1907. Mollusques de l’Isle del Coco. Museo Nacional de Costa Rica, San José, Costa Rica. 30 p. (Traducido: Biolley, P. 1935. Moluscos de la Isla del Coco. Revista del Colegio Superior de Señoritas (San José) 2(6): 2-18.)</li>
                <li>Boulay, J.N., J. Cortés J. Nivia-Ruiz & I.B. Baums. 2012. High genotypic diversity of the reef-building coral Porites lobata (Scleractinia: Poritidae) in Isla del Coco National Park, Costa Rica. Revista de Biología Tropical, 60 (Supplement 3): 279-292.</li>
                <li>Boulay, J.N., M.E. Hellberg, J. Cortés & I.B. Baums. 2014. Unrecognized coral species diversity masks differences in functional ecology. Proceedings Royal Society, B: Biological Sciences, 281, 20131580. DOI: 10.1098/rspb.2013.1580.</li>
                <li>Breedy, O. & J. Cortés. 2008. Octocorals (Coelenterata: Anthozoa: Octocorallia) of Isla del Coco, Costa Rica. Revista de Biología Tropical,56 (Supplement 2): 71-77.</li>
                <li>Breedy, O. & J. Cortés. 2011. Morphology and taxonomy of a new species of Leptogorgia (Cnidaria: Octocorallia: Gorgoniidae) in Cocos Island National Park, Pacific Costa Rica. Proceedings of the Biological Society of Washington, 124, 62-69</li>
                <li>Breedy, O., L.P. van Ofwegen & S. Vargas. 2012. A new family of soft corals (Anthozoa, Octocorallia, Alcyonacea) from the aphotic tropical eastern Pacific waters revealed by integrative taxonomy. Systematics and Biodiversity, 10: 351-359.</li>
                <li>Breedy, O., L. van Ofwegen, C.S. McFadden & C. Murillo-Cruz. 2021. Rhodolitica on rhodoliths: a new stoloniferan genus (Anthozoa: Octocorallia: Alcyonacea). ZooKeys, 1032, 63-77. DOI: 10.3897/zookeys.1032.63431</li>
                <li>Bussing, W.A. 1997. Chriolepis atrimelum (Gobiidae) a new species of gobiid fish from Isla del Coco, Costa Rica. Revista de Biología Tropical, 45: 1547-1552.</li>
                <li>Bussing, W.A. 2010. A new fish, Peristedion nesium (Scorpaeniformes: Peristediidae) from Isla del Coco, Costa Rica. Revista de Biología Tropical, 58: 1149-1156.</li>
                <li>Bussing, W.A. & M.I. López. 2005. Peces de la Isla del Coco y peces arrecifales de la costa Pacífica de América Central meridional / Fishes of Cocos Island and reef fishes of the Pacific coast of lower Central America. Revista de Biología Tropical,53 (Suplemento 2): 1-192.</li>
                <li>Cairns, S.D. 1991. A revision of the ahermatypic Scleractinia of the Galápagos and Cocos Islands. Smithsonian Contribution to Zoology, 504: 1-33.</li>
                <li>Cairns, S.D. 1991. New records of Stylasteridae (Hydrozoa: Hydroida) from the Galápagos and Cocos Islands. Proceedings of the Biological Society of Washington, 104: 209-228.</li>
                <li>Cairns, S.D. 2018. Deep-water octocorals (Cnidaria, Anthozoa) from the Galápagos and Cocos islands. Part 1: Suborder Calcaxonia. ZooKeys, 729: 1-46. DOI: 10.3897/zookeys.729.21779</li>
                <li>Castellanos-Osorio, I., R.M. Hernández-Flores, Á Morales-Ramírez & M. Corrales-Ugalde. 2012. Apendicularias (Urochordata) y quetognatos (Chaetognatha) del Parque Nacional Isla del Coco, Costa Rica. Revista de Biología Tropical, 60 (Suplemento 3), 243-255.</li>
                <li>Cambra, M., R. Cubero, I. Chinchilla & J.C. Azofeifa-Solano. 2018. A new record of the Indo-Pacific Whitespotted surgeonfish, Acanthurus guttatus, in the eastern tropical Pacific (Isla del Coco, Costa Rica). Marine Biodiversity Records, 11: 4. DOI: 10.1186/s41200-018-0139-0</li>
                <li>Cambra, M., F. Lara-Lizardi, C. Peñaherrera- Palma, A. Hearn, J.T. Ketchum, P. Zarate et al. 2021. A first assessment of the distribution and abundance of large pelagic species at Cocos Ridge seamounts (Eastern Tropical Pacific) using drifting pelagic baited remote cameras. PLoS ONE 16(11): e0244343. DOI: 10.1371/journal. pone.0244343</li>
                <li>Cambra, M., S. Madrigal-Mora, I. Chinchilla, G. Golfín-Duarte, C.G. Lowe & M. Espinoza. 2021. First record of a potential neonate tiger shark (Galeocerdo cuvier) at a remote oceanic island in the Eastern Tropical Pacific. Journal of Fish Biology, 1-5. DOI: 10.1111/jfb.14774</li>
                <li>Corrales-Ugalde, M., A. Quesada, B. Naranjo-Elizondo & J. Cortés. 2018. New records of gelatinous zooplankton from an oceanic island in the Eastern Tropical Pacific. Journal of the Marine Biology Association of the United Kingdom, 98 (6): 1219-1226. DOI: 10.1017/S0025315417000558</li>
                <li>Cortés, J. 2012. Marine biodiversity of an Eastern Tropical Pacific oceanic island, Isla del Coco, Costa Rica. Revista de Biología Tropical,60 (Supplement 3): 131-185.</li>
                <li>Cortés, J., A. Sánchez-Jiménez, A. Rodríguez-Arrieta, G. Quirós-Barrantes, P.C. González & S. Blum. 2012. Elasmobranchs observed in deepwaters (45- 330m) at Isla del Coco National Park, Costa Rica (Eastern Tropical Pacific). Revista de Biología Tropical, 60 (Supplement 3): 257-273.</li>
                <li>Dean, H.K., J.A. Sibaja-Cordero & J. Cortés. 2010. Occurrence of the phoronid Phoronopsis albomaculata in Cocos Island, Costa Rica. Pacific Science, 64: 459- 462.</li>
                <li>Dean, H.K., J.A. Sibaja-Cordero, J. Cortés, R. Vargas & G.Y. Kawauchi. 2010. Sipunculids and Echiurans of Isla del Coco (Cocos Island), Costa Rica. Zootaxa, 2557: 60-68.</li>
                <li>Dean, H.K., J.A. Sibaja-Cordero & J. Cortés. 2012. Polychaetes (Annelida: Polychaeta) of Parque Nacional Isla del Coco, Pacific Costa Rica. Pacific Science, 66: 347-386.</li>
                <li>Durham, J.W. 1962. Corals from the Galápagos and Cocos islands. Proceedings of the California Academy of Sciences, 4th Series, 32: 41-56.</li>
                <li>Esquete, P., J.A. Sibaja-Cordero & J.S. Troncoso, J. S. 2013. A new genus and species of Leptocheliidae (Crustacea: Peracarida: Tanaidacea) from Isla del Coco (Costa Rica). Zootaxa, 3741: 228-242.</li>
                <li>Fernández, C. 2008. Flora marina del Parque Nacional Isla del Coco, Costa Rica, Pacífico Tropical Oriental. Revista de Biología Tropical, 56 (Suplemento 2): 57-69.</li>
                <li>Fourriére, M., J.C. Azofeifa-Solano & L. Sandoval. 2016. Species richness and density of seabirds in Isla del Coco bays, Costa Rica, related to the occurrence of breeding colonies. Marine Ornithology, 44: 241-246.</li>
                <li>Fourriére, M., J.J. Alvarado, A.A. Bocos & J. Cortés. 2017. Updated checklist and analysis of completeness of the fish fauna of Isla del Coco, Costa Rica. Marine Biodiversity, 47: 813-821.</li>
                <li>Garrison, G. 2005. Peces de la Isla del Coco / Isla del Coco Fishes, 2nd ed. Editorial INBio, Heredia, Costa Rica. 429 p.</li>
                <li>García-Méndez, K. & Y.E. Camacho-García. 2016. New records of heterobranch sea slugs (Mollusca: Gastropoda) from Isla del Coco National Park, Costa Rica. Revista de Biología Tropical, 64 (Supplement 1): S205-S219.</li>
                <li>Gasca, R. & Á. Morales-Ramírez. 2012. Anfípodos hiperídeos (Crustacea: Peracarida) del Parque Nacional Isla del Coco, Costa Rica, Pacífico Tropical Oriental. Revista de Biología Tropical, 60 (Suplemento 3): 223-233.</li>
                <li>Hertlein, L.G. 1963. Contribution to the biogeography of Cocos Island, including a bibliography. Proccedings of the California Academy of Science, 4th Series, 32: 219-289. (Traducción al español: Nuevos apuntes y bibliografía de la Isla del Coco. Instituto Geográfico Nacional, Serie Geográfica No. 2 (1964): 29-70).</li>
                <li>Jiménez-Cueto, S., E. Suárez-Morales & Á. Morales- Ramírez. 2012. Poliquetos holoplanctónicos (Annelida: Polychaeta) del Parque Nacional de Isla del Coco, Costa Rica. Revista de Biología Tropical, 60 (Suplemento 3): 207-222</li>
                <li>López-Garro, A., I. Zanella, G. Golfín-Duarte, and M. Pérez-Montero. 2012. First record of the blacktip reef shark Carcharhinus melan- opterus (Carcharhiniformes: Carcharhinidae) from the Eastern Trop- ical Pacific. Revista de Biología Tropical, 60 (Supplement 3): 275-278.</li>
                <li>Montoya, M. 1984. Marine mollusks of Cocos Island, Costa Rica. I. Bibliographic compilation of species. Western Society of Malacology, Annual Report, 1983, 16: 33-44</li>
                <li>Montoya, M. 2008. Aves marinas de la Isla del Coco, Costa Rica y su conservación. Revista de Biología Tropical, 56 (Suplemento 2): 133-149</li>
                <li>Montoya, M. 2008. La presencia de otáridos (Carnivora: Otariidae) en la Isla del Coco, Costa Rica. Revista de Biología Tropical, 56 (Suplemento 2): 151-158.</li>
                <li>Morales-Ramírez, A. 2008. Caracterización cualitativa del zooplancton del Área de Conservación Marina Isla del Coco (ACMIC), Océano Pacífico de Costa Rica. Revista de Biología Tropical, 56 (Suplemento 2): 159-169.</li>
                <li>Perger, R., R. Vargas & A. Wall. 2011. Johngarthia cocoensis, a new species of Gecarcinidae MacLeay, 1838 (Crustacea, Decapoda, Brachyura) from Cocos Island, Costa Rica. Zootaxa, 2911: 57-68.</li>
                <li>Salas-Moya, C. & R. Vargas-Castillo. 2016. First record of Coronida glasselli (Stomatopoda: Coronididae) for Costa Rica (Isla del Coco National Park, Eastern Tropical Pacific). Revista de Biología Tropical, 64 (Supplement 1): S231-S233.</li>
                <li>Sánchez-Jiménez, A., B. Naranjo-Elizondo, A. Rodríguez-Arrieta, A.J. Quesada, S. Blum, J.E. McCosker, D.R. Robertson, P.J. Auster & J. Cortés. 2018. Updated catalogue of bony fishes observed in deep waters at Isla del Coco National Park and Las Gemelas Seamount, Costa Rica (Eastern Tropical Pacific). Revista de Biología Tropical, 66 (Supplement 5): S1-S113. DOI: 10.15517/rbt.v66i5</li>
                <li>Sibaja-Cordero, J.A., J. Troncoso & J. Cortés. 2012. The lancelet Asymmetron lucayanum complex in Cocos Island National Park, Pacific Costa Rica. Pacific Science, 66: 521-526.</li>
                <li>Sibaja-Cordero, J.A., K. García-Méndez & J.S. Troncoso. 2013. Additions to the mollusk checklist of Cocos Island National Park, Costa Rica (Eastern Tropical Pacific). Iberus, 31: 127-163.</li>
                <li>Suárez-Morales, E. & R. Gasca. 2012. A new Lepeophtheirus (Copepoda: Siphonostomatoida: Caligidae) from Isla del Coco, Costa Rica, Eastern Tropical Pacific. Revista de Biología Tropical, 60 (Supplement 3): 235-242.</li>
                <li>Suárez-Morales, E. & A. Morales-Ramírez. 2009. New species of Monstrilloida (Crustacea: Copepoda) from the Eastern Tropical Pacific. Journal of Natural History, 43: 1257-1271.</li>
                <li>Torres-Hernández, E., I. Betancourt-Resendes, P. Díaz-Jaimes, A. Angulo, E. Espinoza & O. Domínguez-Domínguez. 2020. Independent evolutionary lineage of the clingfish Gobiesox adustus (Gobiesocidae) from Isla del Coco, Costa Rica. Revista de Biología Tropical, 68 (Supplement 1): S306-S319.</li>
                <li>Vargas-Castillo, R. & I.S. Wehrtmann. 2008. Stomatopods and decapods from Isla del Coco, Pacific Costa Rica. Revista de Biología Tropical, 56 (Supplement 2): 79-97.</li>
                <li>Vargas-Montero, M., Á. Morales-Ramírez & J. Cortés. 2012. Primer informe del género Gambierdiscus (Dinophyceae) en el Parque Nacional Isla del Coco, Pacífico Tropical Oriental. Revista de Biología Tropical, 60 (Suplemento 3): 187-199.</li>
                <li>Violante-Huerta, M., A. Morales-Ramírez & L. Sanvicente-Añorve. 2021. Anfípodos pelágicos (Amphilochidea, Hyperiidea y Senticaudata) del Pacífico de Costa Rica: nuevos registros y una lista de especies actualizada. Revista Ciencias Marinas y Costeras, 13 (1): 77-97. DOI: 10.15359/revmar.13-1.5</li>
              </ol>

              <h2>{referencesText[0].terrestrial}</h2>
              <ol>
                <li>Anderson, R.C., P.L. Wong & T.W. Sherry. 1980. Diplotriaena  muscisaxicolae Schuurmans-Stekhoven, 1952 (Nematoda: Diplotriaenoidea) from Nesotriccus ridgwayi Townsend (Tryannidae) of Cocos Island, Costa Rica. Canadian Journal of Zoology, 58: 1923-1926.</li>
                <li>Arma, L.F. de & C. Víquez. 2014. Aracnofauna de la Isla del Coco, Costa Rica, y descripción de un nuevo Surazomus (Schizomida: Hubbardiidae). Revista Ibérica de Aracnología, 25: 71-75.</li>
                <li>Dean, R. & M. Montoya. 2005. Ornithological observations from Cocos Island, Costa Rica. (April 2005). Zeledonia, 9: 62-69.</li>
                <li>Estrada-Chavaría, A., J. Sánchez-González & A. Rodríguez-González. 2020. Catálogo actualizado de las plantas vasculares del Parque Nacional Isla del Coco, Costa Rica. Revista de Biología Tropical, 68 (Suplemento 1): S73-S88.</li>
                <li>Gómez, L.D. 1975. The ferns and fern-allies of Cocos Island, Costa Rica. American Fern Journal, 65(4): 102-104.</li>
                <li>Gómez, L.D. 1975. Contribuciones a la pteridología costarricense. VII. Pteridofitos de la Isla de Cocos. Brenesia, 6: 33-48.</li>
                <li>Granados-Chacón, J. 2021. Composición y diversidad microbiana del suelo en tres coberturas vegetales del Parque Nacional Isla del Coco, Costa Rica. Tesis de Licenciatura, Instituto Tecnológico de Costa Rica, Cartago, Costa Rica.</li>
                <li>Méndez-Soto, M. 2020. La diversidad del Orden Diptera de la Isla del Coco representada en la colección de artrópodos del Museo Nacional de Costa Rica y otras colecciones. Revista de Biología Tropical, 68 (Suplemento 1): S144-S154.</li>
                <li>Montoya, M. 2003. Aves de la isla del Coco. Lista de Especies / Birds of Cocos Island. Species List / Oiseaux de l’Île de Cocos. Liste des éspèces. Zeledonia, 7: 29- 37.</li>
                <li>Montoya, M. 2006. Aves acuáticas de la Isla del Coco, Costa Rica. Humedal de la Convensión de Ramsar. Zeledonia,10: 42-52.</li>
                <li>Montoya, M. 2009. Insecta, Lepidoptera, Sphingidae, Cocytius antaeus Drury: First record for Isla del Coco, Costa Rica and notes on its hostplant. Check List 5 (1): 151-153.</li>
                <li>Montoya, M & F. López-Pozuelo. 2007. Observaciones ornitológicas en la Isla del Coco, Costa Rica (Marzo-Agosto 2007). Zeledonia 11 (2): 1-11</li>
                <li>Montoya, M. & M. Pascal. 2004. Dos nuevos registros para la avifauna de la Isla del Coco. Zeledonia 8: 7-11.</li>
                <li>Rodríguez, A., O.O. Ortiz & M. Cedeño-Fonseca. 2022. A new species of Stenospermation (Araceae) endemic to Isla del Coco, Costa Rica. Anales del Jardín Botánico de Madrid, 79 (2): e128.  DOI: 10.3989/ajbm.2632</li>
                <li>Rojas-Alfaro, R., R. Umaña-Castro, N. Rojas-Campos & M. Vargas-Montero. 2020. Primer reporte de bacterias y dinoflagelados marinos luminiscentes del Parque Nacional Isla del Coco, Costa Rica. Revista de Biología Tropical, 68 (Suplemento 1): S213-S224.</li>
                <li>Rojas-Alvarado, A.F. 2011. New species and new records of ferns (Pteridophyta: Polypodiales) from Cocos Island, Costa Rica. Brenesia, 75/76: 7-15.</li>
                <li>Rojas-Alvarado, A.F. 2017. A new species and three hybrids in the ferns from Cocos Island, Puntarenas, Costa Rica. Acta Botánica Malacitana, 42 (1): 91-99.</li>
                <li>Rojas-Alvarado, A.F. & J. Trusty. 2004. Diversidad Pteridofítica de la Isla del Coco, Costa Rica. Brenesia, 62: 1-14.</li>
                <li>Ruiz-Boyer, A. & A. Rodríguez-González. 2020. Lista preliminar de hongos (Ascomycota y Basidiomycota) y mixomicetos (Myxomycota) de la Isla del Coco, Puntarenas, Costa Rica. Revista de Biología Tropical, 68 (Suplemento 1): S33-S56.</li>
                <li>Sánchez-González, J. & A. Rodríguez. 2017. Una especie nueva de Tachia (Gentianaceae) de Isla del Coco, Costa Rica. Journal of the Botanical Research Institute of Texas, 11 (2): 327-333.</li>
                <li>Slud, P. 1967. The birds of Cocos Island [Costa Rica]. Bulletin of the American Museum of Natural History, 134: 261-296.</li>
                <li>Trusty, J. & M.A. Blanco. 2005. Las orquídeas de la Isla del Coco. Epidendrum, 27: 10-15.</li>
                <li>Trusty, J.L., H.C. Kesler & G.H. Delgado. 2006. Vascular flora of Isla del Coco, Costa Rica. Proceedings of the California Academy of Sciences, 4th Series, 57 (7): 247-355.</li>
                <li>Trusty, J.L., A. Tye, T.M. Collins, F.A. Michelangeli, P. Madriz & J. Francisco-Ortega. 2012.</li>
                <li>Galápagos and Cocos Islands: Geographically close, botanically distant. International Journal of Plant Science, 173 (1): 36-53. DOI: 10.1086/662657</li>
                <li>Umaña-Tenorio, L.A. 2020. Adiciones a la biota de líquenes de la Isla del Coco, Puntarenas, Costa Rica. Revista de Biología Tropical, 68 (Suplemento 1): S57-S72.</li>
                <li>Víquez, C. 2020. Aracnofauna (Arachnida) de la Isla del Coco, Costa Rica, con la descripción de tres nuevas especies. Revista de Biología Tropical, 68 (Suplemento 1): S115-S143.</li>
              </ol>

              <h2>{referencesText[0].climate}</h2>
              <ol>
                <li>Alfaro, E.J. 2008. Ciclo diario y anual de variables troposféricas y oceánicas en la Isla del Coco, Costa Rica. Revista de Biología Tropical, 56 (Suplemento 2): 19-29.</li>
                <li>Alfaro, E.J. & H.G. Hidalgo. 2016. Climate of an oceanic island in the Eastern Pacific: Isla del Coco, Costa Rica, Central America. Revista de Biología Tropical, 64 (Supplement 1): S59-S74.</li>
                <li>Corrales, J.L., R. Sánchez-Murillo, G. Esquivel-Hernández, E. Herrera & J. Boll. 2016. Tracking the water fingerprints of Cocos Island: a stable isotope analysis of precipitation, surface water, and groundwater. Revista de Biología Tropical, 64 (Supplement 1): S105-S120.</li>
                <li>Durán-Quesada, A.M. & E.J. Alfaro. 2016. A multi-scale analysis of moisture supply associated with precipitation on Isla del Coco, Costa Rica. Revista de Biología Tropical, 64 (Supplement 1): S87-S103.</li>
                <li>Fernández, W. 1984. Comments on meteorological and climatological observations on Cocos Island. Revista de Geofísica, 20: 9-19.</li>
                <li>Maldonado, T., & Alfaro, E. (2010). Comparación de las salidas del Modelo MM5v3 con datos observados en la Isla del Coco. Tecnología en Marcha, 23: 3-28.</li>
                <li>Maldonado, T.J. & E.J. Alfaro. 2012. Revisión y comparación de escenarios de Cambio Climático para el Parque Nacional Isla del Coco, Costa Rica. Revista de Biología Tropical, 60 (Suplemento 3): 83-112.</li>
                <li>Quirós-Badilla, E. & E.J. Alfaro. 2009. Algunos aspectos relacionados con la variabilidad climática en la Isla del Coco, Costa Rica. Revista de Climatología, 9: 33-44.</li>
                <li>Vargas, J.L. & E.J. Alfaro. 2016. Radiación ultravioleta (UV) en el Parque Nacional Isla del Coco, Costa Rica. Revista de Biología Tropical, 64 (Suplemento 1): S75-S86.</li>
              </ol>

              <h2>{referencesText[0].marineEcology}</h2>
              <ol>
                <li>Acevedo-Gutiérrez, A., & B. Würsig. 1991. Preliminary observations on bottlenose dolphins, Tursiops truncatus, at Isla del Coco, Costa Rica. Aquatic Mammals, 17: 148-151.</li>
                <li>Alvarado, J.J. & A. Chiriboga. 2008. Distribución y abundancia de equinodermos de las aguas someras en la Isla del Coco, Pacifico Oriental, Costa Rica. Revista de Biología Tropical, 56 (Suplemento 2): 99-111.</li>
                <li>Alvarado, J.J., J. Cortés, and H. Reyes-Bonilla. 2012. Bioerosion impact model for the sea urchin Diadema mexicanum on three Costa Rican Pacific coral reefs. Revista de Biología Tropical, 60 (Supplement 2): 121-132.</li>
                <li>Alvarado, J.J., A. Beita, S. Mena, C. Fernández-García, A.G. Guzmán-Mora & J. Cortés. 2016. Ecosistemas coralinos del Parque Nacional Isla del Coco, Costa Rica: estructura y comparación 1987-2014. Revista de Biología Tropical, 64 (Suplemento 1): S153-S175.</li>
                <li>Alvarado, J.J., C. Sánchez-Noguera, C.G. Arias-Godínez, T. Araya, C. Fernández-García & A.G. Guzmán. 2020. Impact of El Niño 2015-2016 on the coral reefs of the Pacific of Costa Rica: the potential role of marine protection. Revista de Biología Tropical, 68 (Supplement 1): S271-S282</li>
                <li>Auster, P.J., A. Sánchez-Jiménez, J.A. Rodríguez-Arrieta, A.J. Quesada, C. Pérez, B. Naranjo-Elizondo, S. Blum & J. Cortés. 2016. Facilitative behavioral interactions between deepwater piscivores at Isla del Coco National Park and Las Gemelas Seamount, Costa Rica. Revista de Biología Tropical, 64 (Supplement 1): S187-S196.</li>
                <li>Auster, P.J., J. Cortés, J.J. Alvarado & A. Beita-Jiménez. 2019. Coordinated hunting behaviors of mixed-species groups of piscivores and associated species at Isla del Coco National Park (Eastern Tropical Pacific). Neotropical Ichthyology, 17(1): e180165. DOI: 10.1590/1982-0224-20180165</li>
                <li>Bakus, G.J. 1975. Marine zonation and ecology of Cocos Island, off Central America. Atoll Research Bulletin, 179: 1-9.</li>
                <li>Chávez, E.J., E. Arauz, A. Hearn, E. Nalesso & T. Steiner. 2020. Asociación de tiburones con el Monte Submarino Las Gemelas y primera evidencia de conectividad con la Isla del Coco, Pacífico de Costa Rica. Revista de Biología Tropical, 68 (Suplemento 1): S320-S329.</li>
                <li>Espinoza, M., T. Araya‐Arce, I. Chaves‐Zamora, I. Chinchilla & M. Cambra. 2020. Monitoring elasmobranch assemblages in a data‐poor country from the Eastern Tropical Pacific using baited remote underwater video stations. Scientific Reports, 10, 17175. DOI: 10.1038/s41598-020-74282-8</li>
                <li>Esquivel-Garrote, O. & A Morales-Ramírez. 2020. Community structure of coral reef zooplankton in Isla del Coco National Park, a Natural World Heritage site in the Eastern Tropical Pacific. Revista de Biología Tropical, 68 (Supplement 1): S248-S260.</li>
                <li>Esquivel-Garrote, O. & A. Morales-Ramírez. 2020. Nocturnal variation of the zooplankton community in coral reef substrates at Isla del Coco National Park (Eastern Tropical Pacific). Revista de Biología Tropical, 68 (Supplement 1): S261-S270.</li>
                <li>Fourriére, M., J.J. Alvarado, J. Cortés, M.H. Taylor, A. Ayala-Bocos, J.C. Azofeifa-Solano, A. Arauz, M. Heidemeyer, A. López-Garro, I. Zanella & M. Wolff. 2019. Energy flow structure and role of keystone groups in shallow water environments in Isla del Coco, Costa Rica, Eastern Tropical Pacific. Ecological Modelling, 396: 74-85. DOI: 10.1016/j.ecolmodel.2019.01.004</li>
                <li>Friedlander, A., B. Zgliczynski, E. Ballesteros, O. Aburto-Oropeza, A. Bolaños & E. Sala. 2012. The shallow-water fish assemblage of Isla del Coco National Park, Costa Rica: structure and patterns in an isolated, predator-dominated ecosystem. Revista de Biología Tropical, 60 (Supplement 3): 321-338.</li>
                <li>Gonzáles-Andrés, C., P.F. Lopes, J. Cortés, J.L. Sánchez-Lizaso & M.G. Pennino. 2016. Abundance and distribution patterns of Thunnus albacares in Isla del Coco National Park through Predictive Habitat Suitability Models. PLoS ONE, 11, e0168212.</li>
                <li>González-Andrés, C., J.L. Sánchez-Lizaso, J. Cortés & M.G. Pennino. 2021. Predictive habitat suitability models to aid the conservation of elasmobranchs in the Isla del Coco National Park (Costa Rica). Journal of Marine Systems, 224, 103643. DOI: 10.1016/j.jmarsys.2021.103643</li>
                <li>Guzmán, H. M., & Cortés, J. 1992. Cocos Island (Pacific of Costa Rica) coral reefs after the 1982-83 El Niño disturbance. Revista de Biología Tropical, 40, 309-324</li>
                <li>Guzman, H.M. & J. Cortés. 2007. Reef recovery 20-yr after the 1982-83 El Niño massive mortality. Marine Biology, 151: 401-411.</li>
                <li>Hearn, A., T. Steiner & R. Arauz. 2020. A brief massive aggregation of pelagic swimming crabs Euphylax dovii (Decapoda: Portunidae) at Cocos Island, Costa Rica coincides with onset of El Niño event. Revista de Biología Tropical, 68 (Supplement 1): S289-S295.</li>
                <li>López-Garro, A., I. Zanella, G. Golfín-Duarte & M. Pérez-Montero. 2020. Residencia del tiburón punta blanca de arrecife, Triaenodon obesus (Carcharhiniformes: Carcharhinidae) en las bahías Chatham y Wafer del Parque Nacional Isla del Coco, Costa Rica. Revista de Biología Tropical, 68 (Suplemento 1): S330-S339.</li>
                <li>Sibaja-Cordero, J.A. 2008. Tendencias espacio-temporales de los avistamientos de fauna marina en los buceos turísticos (Isla del Coco, Costa Rica). Revista de Biología Tropical, 56 (Suplemento 2): 113-132.</li>
                <li> Sibaja-Cordero, J.A. 2008. Vertical zonation in the rocky intertidal at Cocos Island (Isla del Coco), Costa Rica: A comparison with other tropical locations. Revista de Biología Tropical, 56 (Supplement 2): 171-187.</li>
                <li>Sibaja-Cordero, J.A. & J. Cortés. 2010. Comparación temporal de la composición y zonación de organismos en el intermareal rocoso del Parque Nacional Isla del Coco, Pacífico de Costa Rica. Revista de Biología Tropical, 58: 1387-1403.</li>
                <li>Sibaja-Cordero, J.A., J. Cortés & H.K. Dean. 2012. Depth diversity profile of polychates worms in Bahía Chatham, Isla del Coco National Park, Pacific of Costa Rica. Revista de Biología Tropical, 60 (Supplement 3): 293-301.</li>
                <li>Solano-Barquero, A., J.A. Sibaja-Cordero & J. Cortés. 2022. Macrofauna associated with a rhodolith bed at an oceanic island in the Eastern Tropical Pacific (Isla del Coco National Park, Costa Rica). Frontiers in Marine Science, 9, 858416. DOI: 10.3389/fmars.2022.858416</li>
                <li>Starr, R.M., J. Cortés, C.L. Barnes, K. Green & O. Breedy. 2012. Characterization of deepwater invertebrates at Isla del Coco National Park and Las Gemelas Seamounts, Costa Rica. Revista de Biología Tropical, 60 (Supplement 3): 303-319.</li>
                <li>Starr, R.M., K. Green & E. Sala. 2012. Deepwater fish assemblages at Isla del Coco National Park and Las Gemelas Seamounts, Costa Rica. Revista de Biología Tropical, 60 (Supplement 3): 347-362.</li>
                <li>Whitney, N.M. & P.J. Motta. 2008. Cleaner host posing behavior of whitetip reef sharks (Triaenodon obesus) in a swarm of hyperiid amphipods. Coral Reefs (Reef Site), 27: 363.</li>
                <li>Whitney, N.M., H.L. Pratt & J.C. Carrier. 2004. Group courtship, mating behaviour and siphon sac function in the whitetip reef shark, Triaenodon obesus. Animal Behavior, 68: 1435-1442.</li>
                <li>Zanella, I., A. López-Garro, G. Golfín-Duarte & J.C. Sáenz. 2012. Biología del tiburón punta blanca de arrecife, Triaenodon obesus (Carcharhiniformes: Carcharhinidae), en Bahía Chatham, Parque Nacional Isla del Coco, Costa Rica. Revista de Biología Tropical, 60 (Suplemento 3): 339-346.</li>
                <li>Zanella, I. A. López-Garro, F. Martínez, G. Golfín-Duarte & K. Morales. 2016. Poblaciones de tiburones en las bahías Chatham y Wafer del Parque Nacional Isla del Coco, Costa Rica. Revista de Biología Tropical, 64 (Suplemento 1): S177-S186.</li>
                <li>Zanella, I., A. López-Garro, D.M. McComb-Kobza, G. Golfín-Duarte, M. Pérez-Montero & J. Morales. 2016. First record of young-of-the-year Scalloped hammerhead shark, Sphyrna lewini (Carcharhiniformes: Sphyrnidae) from Isla del Coco National Park, Costa Rica. Revista de Biología Tropical, 64 (Suplemento 1): S201-S204.</li>
              </ol>

              <h2>{referencesText[0].terrestrialEcology}</h2>
              <ol>
                <li>Acosta-Vargas, L.G. 2016. Population status of the tree Sacoglottis holdridgei (Humiriaceae) at Isla del Coco National Park, Costa Rica. Revista de Biología Tropical, 64 (Supplement 1): S263-S275.</li>
                <li>Acosta-Vargas, L., A.E. Rovere & J. Camacho-Sandoval. 2020. Effectiveness of two treatments to promote tree regeneration: implications for forest restoration in the Isla del Coco National Park, Costa Rica. Revista de Biología Tropical, 68 (Supplement 1): S103-S114. </li>
                <li>Bonilla-Mata, R. & L.G. Acosta-Vargas. 2020. Dynamic and growth of the forests of the Isla del Coco National Park, Costa Rica. Revista de Biología Tropical, 68 (Supplement 1): S89-S102.</li>
                <li>Cotoras, D.D. & M.A. Zumbado. 2020. Aerial plankton from the Eastern Tropical Pacific. Revista de Biología Tropical, 68 (Supplement 1): S155-S162. </li>
                <li>Herrera-Martínez, C.G., M. Montoya & P. Camacho 2011. Observaciones sobre el comportamiento del Cuclillo del Coco Coccyzus ferrugineus Gould, 1843 (Aves: Cuculidae) en la Isla del Coco, Costa Rica. Brenesia, 75/76: 112-114.</li>
                <li>Kroodsma, D.E., V. Ingalls, T.W. Sherry & T.K. Werner. 1987. Songs of the Cocos Flycatcher: Vocal behavior of a Suboscine on an isolated oceanic island.  The Condor, 89: 75-84.</li>
                <li>Porras-Jiménez, M.A., L.G. Acosta-Vargas, M. Castillo-Ugalde & R. Quesada-Monge. 2014. Estructura y composición florística del bosque nuboso de la Isla del Coco. Revista Tecnología en Marcha, 27: 22-36.</li>
                <li>Sherry, T.W. 1985. Adaptations to a novel environment: food, foraging and morphology of the Cocos Island Flycatcher. Ornithological Monograph, 36: 908-920. </li>
                <li>Sherry, T.W. 1986. Nest, eggs, and reproductive behavior of the Cocos Island Flycatcher (Nesotriccus ridgwayi). Condor 88:531-532. </li>
                <li>Sierra, C. 2001. El cerdo cimarrón (Sus scrofa, Suidae) en la Isla del Coco, Costa Rica: composición de la dieta, estado reproductivo y genética. Revista de Biología Tropical, 49 (3/4): 1147-1157.</li>
                <li>Sierra, C. 2001. El cerdo cimarrón (Sus scrofa, Suidae) en la Isla del Coco, Costa Rica: escarbamientos, alteraciones al suelo y erosión. Revista de Biología Tropical, 49 (3/4): 1159–1170.</li>
                <li>Sierra, C. 2002. Contaminación de las aguas dulces por el cerdo cimarrón (Sus scrofa) en la Isla del Coco, Costa Rica. Tecnología en Marcha, 14 (1): 18-23.</li>
                <li>Sierra, C. 2005. Una estrategia para el manejo de especies introducidas en la Isla del Coco, Costa Rica, incluyendo un plan general para la erradicación de seis especies de mamíferos. Unión Mundial para la Conservación de la Naturaleza y Recursos Naturales (UICN), San José, Costa Rica 50 pp.</li>
                <li>Tenorio, J., C. Hidalgo, M. Montoya & O. Ramírez-Alán. 2020. Comportamiento reproductivo, forrajeo y uso de hábitat de Coccyzus ferrugineus (Cuculiformes: Cuculidae) en la Isla del Coco, Costa Rica. Revista de Biología Tropical, 68 (Suplemento 1): S163-S176. </li>
                <li>Werner, T.K. & T.W. Sherry. 1987. Behavioral feeding specialization in Pinaroloxias inornata, the ”Darwin’s Finch” of Cocos Island, Costa Rica. Proceedings of the National Academy of Sciences of the United States of America, 84 (15): 5506-5510.</li>
              </ol>

              <h2>{referencesText[0].economy}</h2>
              <ol>
                <li>Cajiao, M.J. 2008. Aspectos legales relacionados con el Parque Nacional Isla del Coco, Costa Rica. Revista de Biología Tropical, 56 (Suplemento 2): 207-214.</li>
                <li>Chacón, I.S. & F.G. Soley. 2020. Investigación para la toma de decisiones de manejo en áreas marinas protegidas como la Isla del Coco, Costa Rica. Revista de Biología Tropical, 68 (Suplemento 1): S1-S17.</li>
                <li>Gonzáles-Andrés, C., J.L. Sánchez-Lizaso, J. Cortés & M.G. Pennino. 2020. Illegal ﬁshing in Isla del Coco National Park: spatial-temporal distribution and the economic trade-off. Marine Policy, 119: 104023. DOI: 10.1016/j.marpol.2020.104023</li>
                <li>López-Garro, A., I. Zanella, F. Martínez, G. Golfín-Duarte & M. Pérez-Montero. 2016. La pesca ilegal en el Parque Nacional Isla del Coco, Costa Rica. Revista de Biología Tropical, 64 (Suplemento 1): S249-S261.</li>
                <li>Moreno-Díaz, M.L. 2012. Actividades socioeconómicas desarrolladas alrededor y en el Parque Nacional Isla del Coco (Costa Rica) y posibles efectos del cambio climático sobre ellas. Revista de Biología Tropical, 60 (Suplemento 3): 113-129</li>
                <li>Moreno-Díaz, M.L. 2016. Políticas Públicas: Visión general y realidad en el Parque Nacional Isla del Coco, Costa Rica. Revista de Biología Tropical, 64 (Suplemento 1): S277-S290.</li>
                <li>Moreno-Díaz, M.L. 2020. Impacto socioeconómico de la variabilidad climática en pesca y turismo: antecedentes y propuesta metodológica. Revista de Biología Tropical, 68 (Suplemento 1): S18-S32.</li>
              </ol>

              <h2>{referencesText[0].geology}</h2>
              <ol>
                <li>Castillo, P., R. Batiza, D. Vanko, R.E. Malavassi, J. Barquero & E. Fernández. 1988. Anomalously young and old hot-spot traces: I. Geology and petrology of Cocos Island. Bulletin of the Geological Society of America, 100: 1400-1414.</li>
                <li>Lizano, O. G. 2001. Batimetría de la plataforma insular alrededor de la Isla del Coco, Costa Rica. Revista de Biología Tropical, 49 (Suplemento 2), 163-170.</li>
                <li>Lizano, O.G. 2012. Rasgos morfológicos alrededor de la Isla del Coco y de sus montes submarinos vecinos, Pacífico de Costa Rica. Revista de Biología Tropical, 60 (Suplemento 3): 43-51.</li>
                <li>Lizano R., O.G. 2013. Erosión en las playas de Costa Rica, incluyendo la Isla del Coco. InterSedes, 24, 6-27.</li>
                <li>Macintyre, I. G., Glynn, P. W. & Cortés, J. (1993). Holocene reef history in the eastern Pacific: Mainland Costa Rica, Caño Island, Cocos Island, and Galápagos Islands. Proceedings of the 7th International Coral Reef Symposium, Guam, 2, 1174-1184.</li>
                <li>Protti, M., V. González, J. Freymueller & S. Doelger. 2012. Isla del Coco, on Cocos Plate, converges with San Andres Island, on the Caribbean Plate, at 78 mm/ yr. Revista de Biología Tropical, 60 (Supplement 3): 33-41.</li>
                <li>Rojas, W. & G.E. Alvarado. 2012. Geología y contexto geotectónico de la Isla del Coco y la zona marítima frente al Pacífico central de Costa Rica. Revista de Biología Tropical, 60 (Suplemento 3): 15-32.</li>
                <li>Sibaja-Cordero, J.A., J.S. Troncoso, C. Benavides-Varela & J. Cortés. 2012. Shallow water soft and hard bottoms of Isla del Coco National Park, Pacific Costa Rica. Revista de Biología Tropical, 60 (Supplement 3): 53-66.</li>
                <li>Sibaja-Cordero, J.A., J.S. Troncoso & E. Gómez-Ramírez. 2014. Recent surface marine sediments of Cocos Island in Costa Rica. International Journal of Sediment Research, 29: 59-72.</li>
              </ol>

              <h2>{referencesText[0].history}</h2>
              <ol>
                <li>Agassiz, A. 1892. Reports on the dredging operations off the west coast of Central America to the Galápagos, to the west coast of Mexico, and in the Gulf of California, in charge of Alexander Agassiz, carried on by the U.S. Fish Commission steamer “Albatross”, Lieut. Commander Z.L. Tanner U.S.N., commanding. II. General sketch of the expedition of the “Albatross,” from February to May, 1891. Bulletin of the Museum of Comparative Zoölogy, 23: 1-89. </li>
                <li>Alfaro, A. 1899. Flora y fauna de la Isla del Coco. Memoria Secretaría de Fomento, San José, Costa Rica, 1899: 31-36.</li>
                <li>Beebe, W. 1926. The Arcturus Oceanographic Expedition. Zoologica, 8: 1-45.</li>
                <li>Cortés, J. 2008. Historia de la investigación marina de la Isla del Coco, Costa Rica. Revista de Biología Tropical, 56 (Suplemento 2): 1-18.</li>
                <li>Díaz-Bolaños, R.E. 2020. Historia Social de la Ciencia: “Moluscos de la Isla del Coco” (1935) de Paul Biolley. Revista de Biología Tropical, 68 (Suplemento 1): S296-S305. </li>
                <li>Díaz-Bolaños, R.E. & E.J. Alfaro-Martínez. 2013. Diccionario histórico de la toponimia de la Isla del Coco. Editorial Nuevas Perspectivas, San José, Costa Rica.</li>
                <li>Díaz-Bolaños, R.E., E.J. Alfaro & A. Ugalde-Quesada. 2012. Diccionario histórico-toponímico de la Isla del Coco, Costa Rica. Revista de Biología Tropical, 60 (Suplemento 3): 1-14.</li>
                <li>Fraser, C.M. 1943. General account of the scientific work of the Velero III in the eastern Pacific, 1931-1941, Part III: a ten-year list of the Velero III collecting stations (Charts 1-115). With an appendix of collecting stations of the Allan Hancock Foundation for the year 1942. Allan Hancock Pacific Expedition, 1: 259-431.</li>
                <li>Hertlein, L.G. 1963. Contribution to the biogeography of Cocos Island, including a bibliography. Proceedings of the California Academy of Sciences, 4th Series, 32: 123-235.</li>
                <li>Montoya, M. 2007. Notas históricas sobre la ornitología de la Isla del Coco, Costa Rica. Brenesia, 68: 37-57.</li>
                <li>Pittier, H.F. 1899. Apuntamientos preliminares sobre la Isla del Coco, posesión costarricense en el Océano Pacífico, p. 15-28. En: Reproducciones Científicas, Una Expedición y Legislación de la Isla del Coco. Instituto Geográfico de Costa Rica, Ministerio de Transportes. San José, Costa Rica, 1963.</li>
                <li>Pittier, H.F. 1935. Apuntamientos preliminares sobre la Isla del Coco, posesión costarricense en el Océano Pacífico. Revista del Colegio Superior de Señoritas, 2(4-5): 2-11.</li>
              </ol>

              <h2>{referencesText[0].oceanography}</h2>
              <ol>
                <li>Acuña, J., J. Cortés & M. Murillo. 1996/1997. Mapa de sensibilidad ambiental para derrames de petróleo en las costas de Costa Rica. Revista de Biología Tropical, 44(3)/45(1): 463-470.</li>
                <li>Acuña-González, J., J. García-Céspedes, E. Gómez- Ramírez, J.A. Vargas-Zamora & J. Cortés. 2008. Parámetros físico-químicos en aguas costeras de la Isla del Coco, Costa Rica (2001-2007). Revista de Biología Tropical, 56 (Suplemento 2): 49-56.</li>
                <li>Brenes, C., D. Ballestero, R. Benavides, J.P. Salazar & G. Murillo. 2026.  Variations in the geostrophic circulation pattern and thermohaline structure in the Southeast Central American Pacific. Revista de Biología Tropical, 64 (Suplemento 2): S121-S134.</li>
                <li>Lizano, O.G. 2008. Algunas características dinámicas de las aguas alrededor de la Isla del Coco, Costa Rica. Revista de Biología Tropical, 56 (Suplemento 2): 31-48.</li>
                <li>Mora-Escalante, R.E. & J.P. Ureña-Mora. 2020. Simulación de oleaje en los alrededores de la Isla del Coco, Costa Rica. Revista de Biología Tropical, 68 (Suplemento 1): S198-S212.</li>
                <li>Mora-Escalante, R.E., O.G. Lizano, E.J. Alfaro & A. Rodríguez. 2020. Distribución de temperatura y salinidad en campañas oceanográficas recientes en el Pacífico Tropical Oriental de Costa Rica. Revista de Biología Tropical, 68 (Suplemento 1): S177-S197.</li>
              </ol>

              <h2>{referencesText[0].issues}</h2>
              <ol>
                <li>Cortés, J. (Editor). 2008. Investigaciones marinas en la Isla del Coco, Costa Rica / Marine Research at Isla del Coco (Cocos Island), Costa Rica / Recherches marines à l’Ile du Coco, Costa Rica. Revista de Biología Tropical, Volumen 56, Suplemento 2: 217 p. DOI: 10.13140/RG.2.1.1341.1926</li>
                <li>Cortés, J. (Editor). 2012. Investigaciones marinas en el Parque Nacional Isla del Coco, Costa Rica / Marine Research at Isla del Coco National Park, Costa Rica. Revista de Biología Tropical, Volumen 60, Suplemento 3: 394 p. DOI: 10.13140/RG.2.1.1126.4085</li>
                <li>Cortés, J. (Editor). 2016. Investigaciones científicas en el Parque Nacional Isla del Coco y aguas adyacentes del Pacífico de Costa Rica / Scientific Research at Isla del Coco National Park and Adjacent Waters, Pacific Costa Rica. Revista de Biología Tropical, Volumen 64, Suplemento 1: S1-S293.</li>
                <li>Naranjo-Elizondo, Beatriz & J. Cortés (Editores). 2020. Investigaciones científicas en el Parque Nacional Isla del Coco y aguas adyacentes del Pacífico de Costa Rica / Scientific Research at Isla del Coco National Park and Adjacent Waters, Pacific Costa Rica. Revista de Biología Tropical, Volumen 68, Suplemento 1: S1-S339.</li>
              </ol>

              <h2>{referencesText[0].general}</h2>
              <ol>
                <li>Capelli, L. & J. Cortés Núñez (Producción). 2017. Isla del Coco: La isla que hizo grande a Costa Rica. Producciones del Río Nevado, San José, Costa Rica. 232 p.</li>
                <li>Cortés, J. 2012. Bibliografía sobre investigaciones marinas, oceanográficas, geológicas y atmosféricas en el Parque Nacional Isla del Coco y aguas adyacentes, Pacífico Costa Rica. Revista de Biología Tropical, 60(Suplemento 3): 363-392.</li>
                <li>Cortés, J. 2016. Isla del Coco: Coastal and marine ecosystems. In: M. Kappelle, Costa Rican Ecosystems. (pp. 162-191). University of Chicago Press, Chicago and London.</li>
                <li>Weston, C., 1992: La isla del Coco/Cocos Island. Trejos Hnos. Sucesores S.A., San José, Costa Rica. 311 p.</li>
              </ol>

              <h2>{referencesText[0].deep}</h2>
              <ol>
                <li>Alvarado, J.J., J.L. Chacón-Monge, J.C. Azofeifa-Solano & J. Cortés. 2022. Diversity of deep-sea echinoderms from Costa Rica. Frontiers in Marine Science, 9, 918878. DOI: 10.3389/fmars.2022.918878</li>
                <li>Azofeifa-Solano, J.C. & J. Cortés. 2021. Chapter 24: Diversity and biology of deep-water crustaceans in Costa Rica, pp. 633-658. In Hendrickx, M. E. (Ed.), Deep-Sea Pycnogonids and Crustaceans of the Americas. Switzerland: Springer Nature. DOI: 10.1007/978-3-030-58410-8_24</li>
                <li>Breedy, O., L.P. van Ofwegen & S. Vargas. 2012. A new family of soft corals (Anthozoa, Octocorallia, Alcyonacea) from the aphotic tropical eastern Pacific waters revealed by integrative taxonomy. Systematics and Biodiversity, 10: 351-359.</li>
                <li>Cairns, S.D. 2018. Deep-water octocorals (Cnidaria, Anthozoa) from the Galápagos and Cocos islands. Part 1: Suborder Calcaxonia. ZooKeys, 729: 1-46. DOI: 10.3897/zookeys.729.21779 </li>
                <li>Cortés, J. 2019. Chapter 26: Isla del Coco, Costa Rica, Eastern Tropical Pacific. Pp. 465-474. In Loya, Y., Puglise, K. A. & Bridge, T. C. L. (Eds.), Mesophotic Coral Ecosystems. Coral Reefs of the World 12. Springer Nature, Switzerland. DOI: 10.1007/978-3-319-92735-0_26</li>
                <li>Cortés, J. & S. Blum. 2008. Life to 450 m depth at Isla del Coco, Costa Rica. Revista de Biología Tropical, 56 (Supplement 2): 189-206.</li>
                <li>Cortés, J., A. Sánchez-Jiménez, A. Rodríguez-Arrieta, G. Quirós-Barrantes, P.C. González & S. Blum. 2012. Elasmobranchs observed in deepwaters (45- 330m) at Isla del Coco National Park, Costa Rica (Eastern Tropical Pacific). Revista de Biología Tropical, 60 (Supplement 3): 257-273.</li>
                <li>Sánchez-Jiménez, A., B. Naranjo-Elizondo, A. Rodríguez-Arrieta, A.J. Quesada, S. Blum, J.E. McCosker, D.R. Robertson, P.J. Auster & J. Cortés. 2018. Updated catalogue of bony fishes observed in deep waters at Isla del Coco National Park and Las Gemelas Seamount, Costa Rica (Eastern Tropical Pacific). Revista de Biología Tropical, 66 (Supplement 5): S1-S113. DOI: 10.15517/rbt.v66i5</li>
                <li>Starr, R.M., J. Cortés, C.L. Barnes, K. Green & O. Breedy. 2012. Characterization of deepwater invertebrates at Isla del Coco National Park and Las Gemelas Seamounts, Costa Rica. Revista de Biología Tropical, 60 (Supplement 3): 303-319.</li>
                <li>Starr, R.M., K. Green & E. Sala. 2012. Deepwater fish assemblages at Isla del Coco National Park and Las Gemelas Seamounts, Costa Rica. Revista de Biología Tropical, 60 (Supplement 3): 347-362.</li>
              </ol>

            </div>

          </div>

          <Footer />

        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default withRouter(connect(mapStateToProps, null)(References));
